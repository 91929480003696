export default {
  palette: {
    type: 'light',
    primary: {
      main: '#990829',
      dark: '#820924',
      light: '#cb0535',
      greyRealiza: '#8c8f8c',
      greyDarkRealiza: '#5A5E5B',
      mainBlue: '#38568a',
      mainGreen: '#387b85',
      mainPurple: '#b9bbea',
      mainOrange: '#dc8035',
      mainLight: '#ddd8d2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#16a41b',
      dark: '#16a41b',
      light: '#16a41b',
    },
    background: {
      paper: '#fff',
      paperModal: '#fafafa',
      default: '#eee',
      paperHighlight: '#387b8533',
      inactive: '#a3a3a9',
      line: '#A8A8B355',
      hoverPaper: '#c0c2c250',
      attention: '#eb3b33',
      attentionHover: '#bf2407',
      // nav: '#f1eeeb',
      nav: '#fff',
      drawer: '#1a1a1e',
    },
    table: {
      hover: '#eee',
      textCell: '#1a1a1e',
      textHeader: '#fff',
      checkboxBack: '#fff',
      line: '#ccc',
      header: '#26262A',
      everyOtherRow: '#fafafa',
    },
    text: {
      dark: '#000',
      light: '#fff',
      primary: '#202024',
      secondary: '#20202488',
      third: '#20202466',
      tabsText: '#303035',
      primaryNav: '#00000088',
    },
    status: {
      success: '#16a41b',
      fail: '#bb2011',
      warn: '#cfd220',
      info: '#5bc0de',
      successD: '#1fb913',
      failD: '#880000',
      warnD: '#b59c0e',
      infoD: '#4e91d4',
      yellow: '#cfd220',
      orange: '#F37735',
    },
    notification: {
      back: '#fff',
      shadow: '#00000088',
      text: '#353539',
    },
    drawer: {
      listTitle: '#fff',
      icon: '#7d7c85',
      textListInactive: '#7d7c85',
      // arrowOpen: '#d9560b',
      arrowOpen: '#387b85',
      textListSelected: '#387b85',
      circleSelected: '#1b1c21cc',
      textSubListInactive: 'blue',
      textSubListSelected: '#5b5a5f',
      textSubListActive: '#387b85',
      hoverSubListOpen: '#c0c2c250',
      backgroundListOpen: 'transparent',
      backgroundSubSubListOpen: 'transparent',
      hoverSubSubListOpen: '#c0c2c250',
      subSubListActive: '#d9560b12',
    },
  },
};
