import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Comments } from '../../../components/Main/Comments';
import { useAuth } from '../../../context/AuthContext';
import { useLoaderDashboard } from '../../../context/LoadDashContext';
import { useNotification } from '../../../context/NotificationContext';
import { VIDEO_ROUTE } from '../../../routes/routesNames';
import { useStudent } from '../../../services/hooks/get/useStudent';
import { useUpdateCurso } from '../../../services/hooks/set/useUpdateCurso';
import { Container, ProgressContainer, Shadow, Title } from './style';

//undraw_mobile_testing_reah
export default function Video() {
  const { currentUser } = useAuth();
  const { setLoaderDash } = useLoaderDashboard();
  const notification = useNotification();
  const { cursoId } = useParams();

  // const  { data:curso, isLoading:cursoIsLoading } = useCurso({cursoId})
  const { data, isLoading } = useStudent({ cursoId });

  const cursoPrev = data?.curso;
  let curso = { ...cursoPrev };

  const student = data?.student;

  const uploadCursoMutation = useUpdateCurso(cursoId);

  const module = useSelector((state) => state.modules);
  const modules = student ? student[0] : {};
  const history = useHistory();

  const pathname = VIDEO_ROUTE + '/' + cursoId;

  return (
    <Container>
      <Shadow>
        <Title>EPI</Title>
        <ProgressContainer></ProgressContainer>
      </Shadow>
      <Shadow style={{ marginTop: 20 }}>
        <Comments curso={curso} />
      </Shadow>
    </Container>
  );
}
