import {
  Calendar,
  CalendarConector,
  Certification,
  Chat,
  Client,
  Comments,
  CursoCreate,
  CursoInfo,
  Cursos,
  Download,
  Home,
  HomeAdmin,
  Notifications,
  Perfil,
  Profession,
  Statement,
  StatementAdmin,
  Team,
  Video,
  VideoAdmin,
} from '../containers';
import {
  ADMIN_PERFIL_EDIT,
  ADMIN_PROFESSION,
  CALENDAR_ADMIN,
  CALENDAR_CONECTOR,
  CERTIFICATION,
  CHATS,
  CLIENT_ADMIN,
  COMMENTS,
  CURSO_INFO,
  CURSOS,
  CURSOS_CREATE,
  DASHBOARD,
  DOWNLOAD,
  FINANCE,
  HOME_ADMIN,
  NOTIFICATIONS_EMAIL,
  PROFILE,
  STATEMENT,
  TEAM,
  VIDEO,
  VIDEO_ADMIN,
} from './routesNames';

const routes = [
  {
    path: DASHBOARD,
    component: Home,
    exact: true,
  },
  {
    path: CURSOS,
    component: Cursos,
    exact: true,
  },
  {
    path: CURSO_INFO,
    component: CursoInfo,
    exact: true,
  },
  {
    path: CURSOS_CREATE,
    component: CursoCreate,
    exact: true,
  },
  {
    path: VIDEO,
    component: Video,
    exact: true,
  },
  {
    path: VIDEO_ADMIN,
    component: VideoAdmin,
    exact: true,
    admin: true,
  },
  {
    path: HOME_ADMIN,
    component: HomeAdmin,
    admin: true,
  },
  {
    path: CLIENT_ADMIN,
    component: Client,
    admin: true,
  },
  {
    path: PROFILE,
    component: Perfil,
    exact: true,
    admin: true,
  },
  {
    path: CHATS,
    component: Chat,
    admin: true,
  },
  {
    path: COMMENTS,
    component: Comments,
    admin: true,
  },
  {
    path: CERTIFICATION,
    component: Certification,
    admin: true,
  },
  {
    path: STATEMENT,
    component: Statement,
    exact: true,
    admin: true,
  },
  {
    path: FINANCE,
    component: StatementAdmin,
    exact: true,
    admin: true,
  },

  {
    path: ADMIN_PERFIL_EDIT,
    component: Perfil,
    exact: true,
    admin: true,
  },
  {
    path: ADMIN_PROFESSION,
    component: Profession,
    admin: true,
  },
  {
    path: NOTIFICATIONS_EMAIL,
    component: Notifications,
    admin: true,
  },
  {
    path: DOWNLOAD,
    component: Download,
    admin: true,
  },
  {
    path: TEAM,
    component: Team,
    exact: true,
    //isPrivate:true,
    //privateRoute:DASHBOARD,
    //infoUser:['access'],
    //condition:[['admin','master']],
    //Equal:[true],
  },
];

export default routes;
