import Clock from '@material-ui/icons/AccessTime';
import Add from '@material-ui/icons/Add';
import AllOut from '@material-ui/icons/AllOut';
import Apps from '@material-ui/icons/Apps';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import Build from '@material-ui/icons/Build';
import Business from '@material-ui/icons/BusinessTwoTone';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import Cancel from '@material-ui/icons/Cancel';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import Error from '@material-ui/icons/Error';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Face from '@material-ui/icons/Face';
import Facebook from '@material-ui/icons/Facebook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import Group from '@material-ui/icons/Group';
import Help from '@material-ui/icons/Help';
import HighlightOff from '@material-ui/icons/HighlightOff';
import History from '@material-ui/icons/History';
import Instagram from '@material-ui/icons/Instagram';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Mail from '@material-ui/icons/Mail';
import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import NoteIcon from '@material-ui/icons/Note';
import Notifications from '@material-ui/icons/Notifications';
import OfflineBoltTwoToneIcon from '@material-ui/icons/OfflineBoltTwoTone';
import Person from '@material-ui/icons/Person';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Remove from '@material-ui/icons/Remove';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RestorePage from '@material-ui/icons/RestorePage';
import Search from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Storage from '@material-ui/icons/StorageTwoTone';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Today from '@material-ui/icons/Today';
import Twitter from '@material-ui/icons/Twitter';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Work from '@material-ui/icons/Work';
import YouTube from '@material-ui/icons/YouTube';
import React from 'react';
import { AiOutlineClose as Close } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { FiUser as Avatar } from 'react-icons/fi';

import LottieAnimation from '../../lib/lottie';
import { ReactComponent as Logo } from './info.svg';
import Politics from '@material-ui/icons/Subject';
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// import Avatar from '@material-ui/icons/PersonOutlineOutlined';
export const Icons = ({ type, ...props }) => {
  switch (type) {
    case 'Email':
      return <EmailIcon {...props} />;
    case 'Chat':
      return <ChatIcon {...props} />;
    case 'Certification':
      return <NoteIcon {...props} />;
    case 'Money':
      return <MonetizationOnIcon {...props} />;

    case 'Twitter':
      return <Twitter {...props} />;
    case 'Instagram':
      return <Instagram {...props} />;
    case 'LinkedIn':
      return <LinkedIn {...props} />;
    case 'YouTube':
      return <YouTube {...props} />;
    case 'Facebook':
      return <Facebook {...props} />;
    case 'WhatsApp':
      return <WhatsApp {...props} />;

    case 'Menu':
      return <MenuOutlinedIcon {...props} />;
    case 'Work':
      return <Work {...props} />;
    case 'Client':
      return <Face {...props} />;
    case 'Clock':
      return <Clock {...props} />;
    case 'MenuOpen':
      return <MenuOpenOutlinedIcon {...props} />;

    case 'Check':
      return <CheckCircleOutlineIcon {...props} />;
    case 'Warn':
      return <ReportProblemOutlinedIcon {...props} />;
    case 'Error':
      return <ErrorOutlineRoundedIcon {...props} />;
    case 'Info':
      return <BsInfoCircle {...props} />;
    case 'Infos':
      return (
        <Logo
          style={{ marginTop: 3, opacity: 0.7, cursor: 'pointer' }}
          height="20px"
          width="20px"
          {...props}
        />
      );
    case 'InfoShade':
      return (
        <Logo
          style={{ marginTop: 3, opacity: 0.4, cursor: 'pointer' }}
          height="20px"
          width="20px"
          {...props}
        />
      );

    case 'Administrative':
      return <FileCopyIcon {...props} />;
    case 'RH':
      return <RecordVoiceOverIcon {...props} />;
    case 'Technician':
      return <Build {...props} />;
    case 'Engineer':
      return <SettingsIcon {...props} />;
    case 'Admin':
      return <SupervisorAccountIcon {...props} />;

    case 'Storage':
      return <Storage {...props} />;
    case 'Business':
      return <Business {...props} />;
    case 'Apps':
      return <Apps {...props} />;

    case 'History':
      return <History {...props} />;
    case 'CloudDownload':
      return <CloudDownload {...props} />;
    case 'ExitToApp':
      return <ExitToApp {...props} />;
    case 'Politics':
      return <Politics {...props} />;
    case 'Group':
      return <Group {...props} />;
    case 'Person':
      return <Person {...props} />;
    case 'Avatar':
      return <Avatar {...props} />;
    case 'KeyboardArrowRightIcon':
      return <KeyboardArrowRightIcon {...props} />;
    case 'KeyboardArrowLeft':
      return <KeyboardArrowLeftOutlinedIcon {...props} />;
    case 'ArrowForward':
      return <ArrowForwardOutlinedIcon {...props} />;
    case 'KeyboardArrowDownIcon':
      return <KeyboardArrowDownIcon {...props} />;
    case 'ArrowDrop':
      return <ArrowDropDownIcon {...props} />;
    case 'Add':
      return <Add {...props} />;
    case 'Remove':
      return <Remove {...props} />;
    case 'Search':
      return <Search {...props} />;
    case 'Cancel':
      return <Cancel {...props} />;
    case 'Close':
      return <Close {...props} />;
    case 'HighlightOff':
      return <HighlightOff {...props} />;
    case 'ArrowBack':
      return <ArrowBack {...props} />;
    case 'Notifications':
      return <Notifications {...props} />;
    case 'Mail':
      return <Mail {...props} />;
    case 'RestorePage':
      return <RestorePage {...props} />;
    case 'Edit':
      return <Edit {...props} />;
    case 'Archive':
      return <ArchiveOutlinedIcon {...props} />;
    case 'Unarchive':
      return <UnarchiveOutlinedIcon {...props} />;
    case 'AllOut':
      return <AllOut {...props} />;
    case 'Risk':
      return <OfflineBoltTwoToneIcon {...props} />;
    case 'FilterList':
      return <FilterList {...props} />;
    case 'Checklist':
      return <AssignmentTurnedInRoundedIcon {...props} />;
    case 'dotsHorizontal':
      return <MoreHorizRoundedIcon {...props} />;
    case 'dotsVertical':
      return <MoreVertRoundedIcon {...props} />;
    case 'Camera':
      return <CameraAltOutlinedIcon {...props} />;
    case 'Mandatory':
      return <PriorityHighIcon {...props} />;
    case 'Calendar':
      return <Today {...props} />;

    case 'Errors':
      return <Error {...props} />;
    case 'Help':
      return <Help {...props} />;
    case 'Video':
      return <VideoLibrary {...props} />;
    case 'DeleteIcon':
      return <DeleteOutlineIcon {...props} />;

    case 'Load':
      return (
        <div {...props}>
          <LottieAnimation
            lotti="loader"
            height={30}
            width={30}
            isClickToPauseDisabled
          />
        </div>
      );
    default:
      return <Close {...props} />;
  }
};
