import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { NavLogoSCDiv } from './Logo';

const Images = styled.img`
  height: 30px;
  resize: cover;
  @media screen and (max-width: 900px) {
    /* height:25px; */
    display: none;
  }
`;
const ImageIcon = styled.img`
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
    resize: cover;
    height: 30px;
  }
`;

export const NavLogo = React.memo(({ isOpen, ...props }) => {
  const dispatch = useDispatch();
  return (
    <NavLogoSCDiv {...props} onClick={() => {}}>
      {/* re<span style={{color:isOpen?'#000':'#000'}}>conecta</span> */}
      <Images src="/images/LogoRealiza.png" alt="logo" />
      <ImageIcon src="/images/iconRealiza.png" alt="logoIcon" />
    </NavLogoSCDiv>
  );
});
