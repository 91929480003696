import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext';
import { ModalButtons } from '../../../Main/MuiHelpers/ModalButtons';
import writeXlsxFile from 'write-excel-file';
import { useMutation } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import { queryClient } from '../../../../services/queryClient';
import { DateProvider } from '../../../../helpers/DateProvider/implementation';
import { useNotification } from '../../../../context/NotificationContext';
import styled from 'styled-components';
import { InputExcelUsersFile } from '../../../Forms/components/InputExcelUsersFile';

const Download = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #aaa;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #eee;

  svg {
    font-size: 20px;
    margin-right: 5px;
  }
`;

const schema = [
  {
    column: 'ID (opcional)',
    type: String,
    width: 30,
    value: (data) => data?.identification ?? '',
  },
  {
    column: 'CNPJ',
    type: String,
    width: 20,
    value: (data) => data?.companyCNPJ ?? '',
  },
  {
    column: 'E-mail',
    type: String,
    width: 40,
    value: (data) => data?.email ?? '',
  },
  {
    column: 'Nome',
    type: String,
    width: 50,
    value: (data) => data?.name ?? '',
  },
  {
    column: 'CPF',
    type: String,
    width: 20,
    value: (data) => data?.cpf ?? '',
  },
];

export function AddMemberModalExcel({ open, setOpen, setEmails }) {
  const { currentUser } = useAuth();
  const notification = useNotification();
  const dateProvider = new DateProvider();
  const users = queryClient.getQueryData(['users', currentUser.uid]);
  const cursos = queryClient.getQueryData(['cursos']);

  const download = useMutation(
    async () => {
      const activeUser = [];

      const cursosData = cursos.filter(
        (curso) =>
          !curso?.editorState && curso?.published && curso.published === true,
      );

      const cursosSchema = cursosData.map((curso) => {
        if (curso?.subs) {
          return curso.subs.map((epi) => {
            const epiRef = `${curso.name}: ${epi.name}--${epi.id.slice(0, 5)}`;

            return [
              {
                column: epiRef,
                type: Number,
                width: 40,
                value: (data) => data?.epi,
              },
            ];
          });
        }

        const cursoRef = `${curso.name}--${curso.id.slice(0, 5)}`;

        return [
          {
            column: cursoRef,
            type: Number,
            width: 40,
            value: (data) => data?.cursoRef,
          },
        ];
      });

      const cursosSchemaConcat = [].concat.apply(
        [],
        [].concat.apply([], cursosSchema),
      );

      const newSchema = [...schema, ...cursosSchemaConcat];

      await writeXlsxFile([], {
        schema: newSchema, // optional
        fileName: `Planilha_de_alunos-${dateProvider.format().short()}.xlsx`,
      });
      return;
    },
    {
      onSuccess: (response) => {
        notification.success({
          message:
            response || 'Planilha encontra-se em sua área de transferência',
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Error ao baixar planilha',
        });
      },
    },
  );

  function onCloseModal() {
    setOpen(false);
  }

  return (
    <ModalButtons
      open={Boolean(open)}
      disable={false}
      onClose={onCloseModal}
      title={`Importar alunos`}
    >
      <Download onClick={() => download.mutateAsync()}>
        {download.isLoading ? <CircularProgress size={18} /> : <GetAppIcon />}
        <p>Baixar Modelo</p>
      </Download>
      <InputExcelUsersFile onCloseModal={onCloseModal} setEmails={setEmails} />
    </ModalButtons>
  );
}
