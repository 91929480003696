import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../../../../context/AuthContext';
import { useNotification } from '../../../../../context/NotificationContext';
import { useCreateUsers } from '../../../../../services/hooks/set/useCreateUsers';
import { useUpdateUsers } from '../../../../../services/hooks/set/useUpdateUsers';
import {
  ButtonFinal,
  ButtonPaymentMethod,
  Cart,
  ContainerCheckout,
  CursosView,
  Explanation,
  FormLabel,
  PaymentSide,
  PriceTable,
  PriceTag,
  TotalPayment,
  TotalPrice,
  TypePaymentText,
} from './styles';

export const CheckoutPageComponent = ({
  checkoutInfo,
  totalPrice,
  onEnd,
  update,
}) => {
  const { currentUser } = useAuth();

  const mutation = useCreateUsers();
  const mutationUpdate = useUpdateUsers();
  const notification = useNotification();
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);

  const data = checkoutInfo?.data ? checkoutInfo.data : [];
  const hasMonthlyPayment = data.some((item) => item.permission.includes('fm'));
  const hasNormalPayment = data.some((item) => !item.permission.includes('fm'));
  const isAdmin = currentUser.access === 'admin';
  function handleCopy(link, text) {
    navigator.clipboard.writeText(link);
    notification.success({
      message: `${text} copiado com sucesso`,
      modal: true,
    });
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirmPurchase = async () => {
    if (!checked && hasMonthlyPayment && isAdmin)
      return notification.warn({
        message:
          'Para finalizar pagamento, confirme que está ciente sobre nossos temos',
        modal: true,
      });

    if (update) {
      await mutationUpdate.mutateAsync({
        user: currentUser,
        noStatement: isAdmin,
        ...checkoutInfo,
      });
    } else {
      await mutation.mutateAsync({
        user: currentUser,
        noStatement: isAdmin,
        ...checkoutInfo,
      });
    }
    onEnd();
  };

  return (
    <ContainerCheckout>
      <Cart>
        <h1>Dados da Compra</h1>
        <h2>Você possui {data.length} novos cadastros</h2>
        <PriceTable>
          {data.map((user, index) => {
            const name = user?.name ? user.name : '________________';
            const cpf = user?.cpf ? user.cpf : '___________________';
            const cursos = user.cursos;

            const info = user?.email ? 'E-mail' : 'Link';
            const infoData = user?.email ? user?.email : user?.link;

            const price = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(
              user?.statement && user.statement[0] && user.statement[0]?.value
                ? user.statement[0].value
                : 0,
            );

            return (
              <PriceTag key={user.uid}>
                {user?.name && (
                  <p className="name">
                    Nome: <span>{name}</span>
                  </p>
                )}
                {user?.cpf && (
                  <p className="cpf">
                    CPF: <span>{cpf}</span>
                  </p>
                )}
                <p onClick={() => handleCopy(infoData, info)} className="info">
                  {info}: <span>{infoData}</span>
                </p>
                <p className="price">
                  Preço: <span>{price}</span>
                </p>
                <CursosView>
                  <span>Cursos: </span>
                  {cursos.map((curso) => {
                    const hasEpi = curso?.epi ? curso.epi : [];
                    return (
                      <div key={curso.name}>
                        <p>{curso.name}</p>
                        {hasEpi.map((epi) => {
                          return <p key={epi.name}>{epi.name}</p>;
                        })}
                      </div>
                    );
                  })}
                </CursosView>
              </PriceTag>
            );
          })}
        </PriceTable>
        <TotalPrice>
          <p>
            Total:{' '}
            <span>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(totalPrice)}
            </span>
          </p>
        </TotalPrice>
      </Cart>
      <PaymentSide>
        <h1>Pagamento</h1>
        <TypePaymentText>Método de pagamento:</TypePaymentText>
        {hasMonthlyPayment && (
          <ButtonPaymentMethod>Faturamento mensal</ButtonPaymentMethod>
        )}
        {hasNormalPayment && isAdmin && (
          <ButtonPaymentMethod>Pagamento efetuado</ButtonPaymentMethod>
        )}
        {hasMonthlyPayment && (
          <>
            <Explanation>
              Está compra <b>{isAdmin ? 'NÃO ' : ''}</b> irá entrar dentro do
              faturamento mensal {isAdmin ? ', para entrar ' : ''} junto com
              todas as outras despesas provenientes dos serviços da área de
              medicina e segurança do trabalho realizados pela empresa{' '}
              <b>Realiza</b> neste ultimo més{' '}
              {isAdmin
                ? 'será necessário que o próprio usuário realize a compra destes cursos em sua área'
                : ''}
              .
            </Explanation>
            <FormLabel
              control={
                <Checkbox
                  checked={checked}
                  size={'small'}
                  onChange={handleChange}
                  style={{ marginTop: -6 }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                isAdmin
                  ? 'Ao inserir a empresa, o administrador confirma o faturamento mensal'
                  : 'Confirmo que estou ciente de como funciona o faturamento mensal'
              }
            />
          </>
        )}
        {hasNormalPayment && (
          <>
            <FormLabel
              control={
                <Checkbox
                  checked={checked}
                  size={'small'}
                  onChange={handleChange}
                  style={{ marginTop: -6 }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                'Você, ao efetuar está operação, está ciente que o pagamento já foi efetuado pelo cliente previamente e não haverá cobranças por parte da plataforma'
              }
            />
          </>
        )}
        <TotalPayment>
          Total a pagar:
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalPrice)}
          </span>
        </TotalPayment>
        <ButtonFinal
          disabled={mutationUpdate.isLoading || mutation.isLoading}
          onClick={handleConfirmPurchase}
        >
          Finalizar Compra
        </ButtonFinal>
      </PaymentSide>
    </ContainerCheckout>
  );
};

export const CheckoutPage = React.memo(CheckoutPageComponent);
