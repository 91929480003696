import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useLoaderDashboard } from '../../../context/LoadDashContext';

import { CURSOS } from '../../../routes/routesNames';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  height: 93%;
  padding-right: 40px;

  @media screen and (max-width: 1100px) {
    padding-right: 40px;
  }

  @media screen and (max-width: 700px) {
    padding-right: 15px;
  }
`;

//undraw_mobile_testing_reah
export default function Home() {
  const { setLoaderDash } = useLoaderDashboard();

  useEffect(() => {
    setLoaderDash(false);
  }, []);
  return (
    <Container>
      <a href="https://www.gruporealizaweb.com.br/">
        <img
          style={{ objectFit: 'contain', width: '100%', borderRadius: '20px' }}
          alt="quem somos"
          src="https://static.wixstatic.com/media/77b0be_e67e680122334bb3ac9ed91ec21cb563~mv2.jpg/v1/fill/w_3000,h_760,al_c,q_90/77b0be_e67e680122334bb3ac9ed91ec21cb563~mv2.webp"
        />
      </a>
      {/* <Redirect
        to={{
          pathname: CURSOS,
        }}
      /> */}
    </Container>
  );
}
