import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ReactComponent } from '../assets/images/notFound.svg';
import { useNotification } from '../context/NotificationContext';

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Paper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.background.line};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 10px;
  padding: 10px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.44);

  p {
    font-size: 2rem;
    margin-top: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const NotFound = () => {
  const history = useHistory();
  const notification = useNotification();
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace('/login');
    }, 3000);
    notification.warn({
      message: 'Pagina não encontrada, redirecionando em 3 segundos',
    });
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      <Paper>
        <p>PAGINA NÃO ENCONTRADA</p>
        <ReactComponent style={{ marginTop: -50 }} width="500px" />
      </Paper>
    </Container>
  );
};

export default NotFound;
