import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer';
import saveReducer from './reducers/saveReducer';
import modulesReducer from './reducers/modulesReducer';
import progressReducer from './reducers/progressReducer';
import draftAbout from './reducers/draftAbout';
import draftPublic from './reducers/draftPublic';

export default combineReducers({
  user: userReducer,
  draftAbout: draftAbout,
  draftPublic: draftPublic,
  save: saveReducer,
  modules: modulesReducer,
  progress: progressReducer,
});
