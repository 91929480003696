/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-spread */
/* eslint-disable prettier/prettier */
/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import readXlsxFile from 'read-excel-file';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { useSellingData } from '../../../../context/data/SellingContext';

import { useNotification } from '../../../../context/NotificationContext';
import { queryClient } from '../../../../services/queryClient';

type IRecords = Record<string, '0' | '1'>;
interface IRowsDTO {
  name: string;
  email: string;
  uid: string;
  cpf: string;
}

type IRows = IRecords & IRowsDTO;

const Text = styled.h3`
  margin: 10px 0px 5px 0;
  font-weight: normal;
  max-width: 90%;
`;

const Input = styled.input`
  margin-right: auto;
  width: 97%;
  margin: 5px 10px 10px 0;
  padding: 20px;
  border: 2px dashed ${({ theme }) => theme.palette.background.line};
  border-radius: 10px;
`;

// interface IInputProps {}

export const InputExcelUsersFile = ({
  setEmails,
  onCloseModal,
}: any): React.ReactElement => {
  const notification = useNotification();
  const ref = React.useRef<HTMLInputElement>(null);
  const cursos = queryClient.getQueryData<any>(['cursos']);

  const { onCalcUserPrice, setCursos, setDataUser, setPrices } =
    useSellingData();

  // const resolveExcelFile = async (
  //   file: newFile,
  // ): Promise<{ moduleData: any; questionsData: any }> => {
  //   const schema = {
  //     About: {
  //       prop: 'module',
  //       type: {
  //         'Nome do teste': {
  //           prop: 'name',
  //           type: String,
  //         },
  //         'Tempo máximo em minutos': {
  //           prop: 'maxTime',
  //           type: Number,
  //         },
  //         'Porcentagem de acertos para passar': {
  //           prop: 'percentageToPass',
  //           type: String,
  //         },
  //       },
  //     },
  //     QUESTIONS: {
  //       prop: 'question',
  //       type: {
  //         texto: {
  //           prop: 'text',
  //           type: String,
  //           required: true,
  //         },
  //         resposta: {
  //           prop: 'answer',
  //           type: String,
  //           required: true,
  //         },
  //         explicação: {
  //           prop: 'why',
  //           type: String,
  //         },
  //         options: {
  //           prop: 'options',
  //           type: {
  //             a: {
  //               prop: 'a',
  //               type: String,
  //               required: true,
  //             },
  //             b: {
  //               prop: 'b',
  //               type: String,
  //               required: true,
  //             },
  //             c: {
  //               prop: 'c',
  //               type: String,
  //             },
  //             d: {
  //               prop: 'd',
  //               type: String,
  //             },
  //             e: {
  //               prop: 'e',
  //               type: String,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   };
  //   const { rows, errors } = await readXlsxFile(file, { schema });
  //   const moduleData = rows.find((i: any) => i?.module).module;
  //   const questionsData = rows.map((i: any) => {
  //     return {
  //       text: i.question.text,
  //       answer:
  //         i.question.options[i.question.answer] ||
  //         Object.values(i.question.options).find(
  //           (fi: any) => i.question?.answer && fi === i.question.answer,
  //         ),
  //       why: i.question?.why ? i.question?.why : '',
  //       id: v4(),
  //       options: Object.values(i.question.options)
  //         .map((text: any) => ({ text }))
  //         .filter((t: any) => t.text),
  //     };
  //   });
  //   return { moduleData, questionsData };
  // };

  const createUsers = (rows: IRows[]) => {
    const _dataUser = {} as any;
    // const _prices = {} as any;
    const _cursos = {} as any;
    const _emails = [] as any[];

    rows.map((user, index) => {
      _dataUser[`${index}--name`] = user?.name || '';
      _dataUser[`${index}--cpf`] = user?.cpf || '';
      _dataUser[`${index}--companyCNPJ`] = user?.companyCNPJ || '';
      _dataUser[`${index}--identification`] = user?.identification || '';

      Object.entries(user).map(([key, value]) => {
        const keySplit = key.split('--');
        // is a simple course

        if (keySplit.length === 2) {
          const cursoName = keySplit[0];
          const cursoId = keySplit[1];
          _cursos[`${index}--${cursoId}--${cursoName}`] = value === '1';
        }
        if (keySplit.length === 4) {
          const cursoName = keySplit[0];
          const cursoId = keySplit[1];
          const epiName = keySplit[2];
          const epiId = keySplit[3];
          if (value === '1') {
            const epis = _cursos[`${index}--${cursoId}--${cursoName}--epi`];
            _cursos[`${index}--${cursoId}--${cursoName}`] = true;

            if (!(epis && Array.isArray(epis))) {
              _cursos[`${index}--${cursoId}--${cursoName}--epi`] = [];
            }

            _cursos[`${index}--${cursoId}--${cursoName}--epi`].push({
              id: epiId,
              name: epiName,
            });
          }
        }
      });

      if (user?.email && user.email.length > 1) {
        _emails[index] = user.email;
      } else {
        const code = `${Math.random().toString(36).slice(2, 10)}${Math.random()
          .toString(36)
          .slice(2, 10)}`;

        const link = `link-url-${code}`;

        _emails[index] = link;
      }
    });

    return {
      _dataUser,
      _cursos,
      _emails,
    };
  };

  const onInputFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    const cursosData = cursos.filter((curso: any) => !curso?.editorState);

    const schema = {
      // 'ID do sistema (Não editar este campo)': {
      //   prop: 'uid',
      //   type: String,
      // },
      'ID (opcional)': {
        prop: 'identification',
        type: String,
      },
      CNPJ: {
        prop: 'companyCNPJ',
        type: String,
      },
      'E-mail': {
        prop: 'email',
        type: String,
      },
      Nome: {
        prop: 'name',
        type: String,
      },
      CPF: {
        prop: 'cpf',
        type: String,
      },
    } as any;

    cursosData.map((curso: any) => {
      if (curso?.subs) {
        return curso.subs.map((epi: any) => {
          schema[`${curso.name}: ${epi.name}--${epi.id.slice(0, 5)}`] = {
            prop: `${curso.name}--${curso.id}--${epi.name}--${epi.id}`,
            type: String,
          };
        });
      }
      schema[`${curso.name}--${curso.id.slice(0, 5)}`] = {
        prop: `${curso.name}--${curso.id}`,
        type: String,
      };
    });

    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const { rows } = await readXlsxFile(file, {
          schema,
        });
        const insertUsersData = createUsers(rows);
        setDataUser(insertUsersData._dataUser);
        setCursos(insertUsersData._cursos);
        setEmails(insertUsersData._emails);
        setPrices(onCalcUserPrice(insertUsersData._cursos));
        onCloseModal();
        if (ref.current) ref.current.value = '';
      }
    } catch (error) {
      if (typeof error !== 'string') return console.log('error', error);
      switch (error) {
        case 'wrong_path':
          break;

        default:
          notification.error({ message: error });
          break;
      }
      if (ref.current) ref.current.value = '';
      console.log('error', error);
    }
  };

  return (
    <div>
      <Text>
        Selecione o arquivo excel com os dados dos alunos conforme o modelo a
        cima
      </Text>
      <Input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={onInputFileChange}
        type="file"
        ref={ref}
      />
    </div>
  );
};
