/* eslint-disable react/jsx-curly-newline */
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useAuth } from '../../../../../../context/AuthContext';
import { useSellingData } from '../../../../../../context/data/SellingContext';
import { useNotification } from '../../../../../../context/NotificationContext';
import { DateProvider } from '../../../../../../helpers/DateProvider/implementation';
import { queryClient } from '../../../../../../services/queryClient';
import { SubCursosSideBar } from '../SubCursos';

const Check = styled(Checkbox)`
  height: 35px;
  width: 35px;
`;

const ItemCurso = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 35px;
  grid-template-rows: 1fr fit-content;
  grid-gap: 0 10px;
  margin-top: 0px;
  padding: 7px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.background.line};

  div.image {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
  }

  div.checkbox {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    align-self: center;
    height: 35px;
    width: 35px;
  }

  h1 {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-top: 2px;
  }

  > p {
    /* background-color: ${({ theme }) => theme.palette.status.success}; */
    color: ${({ theme }) => theme.palette.text.secondary};
    width: fit-content;
    padding: 2px 0px;
    font-weight: bold;
    font-size: 0.75rem;
    /* opacity:0.3; */

    span {
      /* color: ${({ theme }) => theme.palette.status.warnD}; */
      /* color: ${({ theme }) => theme.palette.primary.main}; */
      font-size: 0.85rem;
      margin-left: 5px;
    }
  }
`;

const ValidationView = styled.div`
  display: flex;
  padding: 5px 0;

  p {
    padding: 2px 10px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.background.line};
    color: #101010;
    background-color: transparent;
    ${(props) =>
      !props.active &&
      css`
        color: white;
        background-color: ${({ theme }) => theme.palette.primary.main};
        border: none;
      `};

    &:hover {
      filter: contrast(60%);
    }
  }

  p + p {
    margin-left: 20px;
    border: 1px solid ${({ theme }) => theme.palette.background.line};
    background-color: transparent;
    color: #101010;
    ${(props) =>
      props.active &&
      css`
        background-color: ${({ theme }) => theme.palette.primary.main};
        border: none;
        color: white;
      `};
  }
`;

export function CursosSideBar({ email, isAdmin }) {
  const { currentUser } = useAuth();
  const notification = useNotification();
  const [newCurso, setNewCurso] = useState({});
  const {
    fieldEdit,
    cursos,
    setCursos,
    prices,
    setPrices,
    onCalcUserPrice,
    credit,
    setCredit,
  } = useSellingData();

  const cursosAllData = queryClient.getQueryData('cursos');
  const cursosFullData = cursosAllData.filter(
    (i) => i?.modules && i?.published,
  );
  // const cursosFullData = isAdmin ?cursosAllData.filter(i=>i?.modules):currentUser?.availableCursos;

  const handleCheck = (event, curso, hasSubCurso) => {
    const newData = { ...cursos };
    if (newData[`${fieldEdit.index}--${curso.id}--${curso.name}`] == 'lock')
      return notification.warn({
        message:
          'Você não pode remover um curso que já foi iniciado pelo aluno',
      });

    newData[`${fieldEdit.index}--${curso.id}--${curso.name}`] =
      event.target.checked;

    if (hasSubCurso) {
      if (event.target.checked)
        newData[`${fieldEdit.index}--${curso.id}--${curso.name}--epi`] = [];
      if (!event.target.checked)
        delete newData[`${fieldEdit.index}--${curso.id}--${curso.name}--epi`];
    }
    setPrices(onCalcUserPrice(newData));
    setCursos(newData);
  };

  return (
    <>
      {cursosFullData
        ? cursosFullData.map((curso) => {
            const cursoImage = curso?.image;
            const cursoName = curso?.name;

            const hasSubCurso = !!curso?.subCursos;
            const combos = curso?.combos ?? [];

            const selectedEpis = !hasSubCurso
              ? 0
              : curso?.subs
                  .map((epi) => {
                    return Boolean(
                      cursos[
                        `${fieldEdit.index}--${curso.id}--${curso.name}--epi`
                      ] &&
                        cursos[
                          `${fieldEdit.index}--${curso.id}--${curso.name}--epi`
                        ].findIndex((i) => i.id == epi.id) != -1,
                    );
                  })
                  .filter((i) => i).length;

            const isUniqueProduct =
              combos.length === 1
                ? 'Preço único:'
                : `Preço ${selectedEpis ? 'total' : 'inicial'}:`;

            const check = Boolean(
              cursos[`${fieldEdit.index}--${curso.id}--${curso.name}`],
            );

            const validDate = cursos[`${fieldEdit.index}//${curso.id}`];

            const onNew = () => {
              if (newCurso[curso.id]) return;
              const CURSO_KEY = `${fieldEdit.index}--${curso.id}--${curso.name}`;
              const CURSO_EPI_KEY = `${fieldEdit.index}--${curso.id}--${curso.name}--epi`;
              const NEW_KEY = `${fieldEdit.index}/-/${curso.id}/-/new`;

              const CURSO_DATA = cursos[CURSO_KEY];
              const CURSO_EPI_DATA = cursos[CURSO_EPI_KEY];
              const CREDIT_DATA = credit[fieldEdit.index];
              setNewCurso({
                ...newCurso,
                [curso.id]: {
                  [CURSO_KEY]: CURSO_DATA,
                  [CURSO_EPI_KEY]: CURSO_EPI_DATA,
                  credit: CREDIT_DATA,
                },
              });

              const newCredit = [...credit];
              const newPrice = [...prices];

              newPrice[fieldEdit.index] =
                newPrice[fieldEdit.index] - newCredit[fieldEdit.index];
              setPrices([...newPrice]);

              newCredit[fieldEdit.index] = 0;
              setCredit([...newCredit]);

              setCursos((cs) => {
                console.log('antes', cs);
                const newCs = { ...cs };
                delete newCs[CURSO_KEY];
                delete newCs[CURSO_EPI_KEY];
                newCs[NEW_KEY] = true;
                console.log('depois', newCs);
                return {
                  ...newCs,
                };
              });
            };

            const onOld = () => {
              if (!newCurso[curso.id]) return;

              const CURSO_KEY = `${fieldEdit.index}--${curso.id}--${curso.name}`;
              const CURSO_EPI_KEY = `${fieldEdit.index}--${curso.id}--${curso.name}--epi`;
              const NEW_KEY = `${fieldEdit.index}/-/${curso.id}/-/new`;

              setCursos((cs) => {
                const newCs = { ...cs };
                delete newCs[NEW_KEY];
                return {
                  ...newCs,
                  [CURSO_KEY]: newCurso[curso.id][CURSO_KEY],
                  [CURSO_EPI_KEY]: newCurso[curso.id][CURSO_EPI_KEY],
                };
              });

              const newCredit = [...credit];
              const newPrice = [...prices];

              newCredit[fieldEdit.index] = newCurso[curso.id].credit;
              setCredit([...newCredit]);

              newPrice[fieldEdit.index] =
                newPrice[fieldEdit.index] + newCredit[fieldEdit.index];
              setPrices([...newPrice]);

              setNewCurso({
                ...newCurso,
                [curso.id]: false,
              });
            };
            if (!combos) return;
            if (!combos[selectedEpis]) return;
            if (combos[selectedEpis] && !combos[selectedEpis]?.price) return;
            return (
              <div key={curso.id}>
                <ItemCurso image={cursoImage}>
                  <div className="image" alt={cursoName} />
                  <h1>{curso.name}</h1>
                  <p>
                    {isUniqueProduct}
                    <span>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(combos[selectedEpis].price)}
                    </span>
                  </p>
                  <div className="checkbox">
                    <Check
                      size="small"
                      checked={check}
                      onChange={(e) => handleCheck(e, curso, hasSubCurso)}
                      color="primary"
                    />
                  </div>
                </ItemCurso>
                {validDate && (
                  <>
                    <p>
                      Curso com <b>vencimento</b>:
                    </p>
                    <ValidationView active={newCurso[curso.id] ? 1 : 0}>
                      <p onClick={onOld}>
                        {new DateProvider().format().timeAgo(validDate)}
                      </p>
                      <p onClick={onNew}>em {curso.cursoValidation} meses</p>
                    </ValidationView>
                  </>
                )}
                <SubCursosSideBar
                  hasSubCurso={hasSubCurso}
                  check={check}
                  isAdmin={isAdmin}
                  curso={curso}
                />
              </div>
            );
          })
        : null}
    </>
  );
}
