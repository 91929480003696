/* eslint-disable react/jsx-curly-newline */
import { alpha as fade } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useAuth } from '../../../../../context/AuthContext';
import { useSellingData } from '../../../../../context/data/SellingContext';
import { SIGN } from '../../../../../routes/routesNames';
import { CompanyInfoInputs } from './CompanyInfo';
import { CursosSideBar } from './Cursos';
import { PermissionSelect } from './Permission';
import { UserInfoInputs } from './UserInfo';

// import AddModal, {Type,Form} from './comp'
// import { useSelector, useDispatch } from 'react-redux';
const PriceText = styled.strong`
  margin: 10px 0 10px 0;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  width: fit-content;
  /* background-color: ${({ theme }) =>
    fade(theme.palette.primary.greyRealiza, 1)}; */
  background-color: #000000aa;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  span {
    margin-left: 10px;
  }
`;

const SideEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  -webkit-box-shadow: 3px 3px 11px 1px rgba(0, 0, 0, 0.23);
  box-shadow: 3px 3px 11px 1px rgba(0, 0, 0, 0.23);
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow-y: auto;
  max-height: 85vh;
  border-radius: 20px;
  width: 100%;
  @media screen and (max-width: 800px) {
    grid-area: side;
  }
  div.selected {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h2 {
      font-size: 1.3rem;
      color: ${({ theme }) => theme.palette.text.primary};
      margin-bottom: ${({ isUrl }) => (isUrl ? 10 : 0)}px;
    }
    > p {
      font-size: 1rem;
      color: ${({ theme }) => theme.palette.text.primary};
      margin-bottom: 10px;
    }
  }

  .none {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export function SideEmail({ isNewClient }) {
  const { currentUser } = useAuth();

  const {
    fieldEdit,
    dataUser,
    setDataUser,
    prices,
    setPermissions,
    permissions,
    credit: credits,
  } = useSellingData();
  const fieldIndex = fieldEdit?.index;

  const isAdmin = currentUser?.access === 'admin';
  const isUrl = fieldEdit.value && fieldEdit.value.includes(SIGN);
  const isCNPJ =
    permissions[`${fieldIndex}--co`] && !permissions[`${fieldIndex}--coea`];

  const price = prices[fieldIndex];
  const credit = credits[fieldIndex];
  // const price = isBilling ? prices[fieldIndex] : 0

  return (
    <SideEmailContainer isUrl={isUrl}>
      {fieldEdit?.value ? (
        <div className="selected">
          {!isCNPJ ? (
            <UserInfoInputs
              isUrl={isUrl}
              fieldEdit={fieldEdit}
              dataUser={dataUser}
              setDataUser={setDataUser}
            />
          ) : (
            <CompanyInfoInputs
              isUrl={isUrl}
              fieldEdit={fieldEdit}
              dataUser={dataUser}
              setDataUser={setDataUser}
            />
          )}
          <PermissionSelect
            fieldEdit={fieldEdit}
            setPermissions={setPermissions}
            permissions={permissions}
            isNewClient={isNewClient}
            isAdmin={isAdmin}
          />
          {/* {isAdmin && (
            <TitleSection isAddClient={isAddClient}>Cursos</TitleSection>
          )} */}

          <PriceText>
            Preço:
            <span>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(
                price ? (credit ? -Number(credit) + Number(price) : price) : 0,
              )}
            </span>
          </PriceText>

          {/* {isAddClient && isAdmin ? (
            <CursosSideBarAdmin
              email={fieldEdit}
              isAdmin={isAdmin}
              setCursos={setCursos}
              cursos={cursos}
              setPermissions={setPermissions}
              permissions={permissions}
            />
          ) : ( */}
          <CursosSideBar isAdmin={isAdmin} />
          {/* )} */}
        </div>
      ) : (
        <p className="none">
          Nenhum email <br /> selecionado
        </p>
      )}
    </SideEmailContainer>
  );
}
