import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDebounce } from '../../../../../hooks/useDebounceJs';
import InputSearch from '../../../../Dashboard/Components/Standard/Search';
import { Icons } from '../../../../Icons/iconsDashboard';

const FilterComponents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
`;

export function FilterComponent(props) {
  const [search, setSearch] = useState('');

  function onTimeoutFunction(value) {
    if (props.setLoading && !props.continueLoading) props.setLoading(false);
    props.onStop(value);
  }

  const [onDebounce] = useDebounce(onTimeoutFunction, 500);

  function onInputSearch(e) {
    if (props.setLoading) props.setLoading(true);
    onDebounce(e.target.value);
    setSearch(e.target.value);
  }

  function onCleanSearch(e) {
    setSearch('');
    if (props.onCleanSearch) props.onCleanSearch();
    if (props.setLoading) props.setLoading(false);
  }

  return (
    <FilterComponents style={props?.style ?? {}}>
      <InputSearch
        icons={Icons}
        onInputSearch={onInputSearch}
        search={search}
        onCleanSearch={onCleanSearch}
      />
      {props.children}
    </FilterComponents>
  );
}
