import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { LoaderDashboard } from './components/Main/Loader';
import { WidgetComponent } from './components/Widget';
import { AuthProvider } from './context/AuthContext';
import { LoaderContext } from './context/LoadDashContext';
import LoaderProvider from './context/LoaderContext';
import NotificationProvider from './context/NotificationContext';
import { RouteComponent, routes } from './routes';
// import { Seed } from './seed';
import { queryClient } from './services/queryClient';
import themeColor from './styles/colors';
import { GlobalStyle } from './styles/global';

const ThemeColor = createTheme(themeColor);

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <QueryClientProvider client={queryClient}>
      {/* <Seed /> */}
      <MuiThemeProvider theme={ThemeColor}>
        <ThemeProvider theme={ThemeColor}>
          <LoaderContext>
            <LoaderDashboard>
              <NotificationProvider>
                <LoaderProvider>
                  <Router>
                    <AuthProvider>
                      <Switch>
                        {routes.map((route) => (
                          <RouteComponent key={route.path} {...route} />
                        ))}
                      </Switch>
                      <WidgetComponent />
                    </AuthProvider>
                  </Router>
                  <GlobalStyle />
                </LoaderProvider>
              </NotificationProvider>
            </LoaderDashboard>
          </LoaderContext>
        </ThemeProvider>
      </MuiThemeProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </ErrorBoundary>
);
