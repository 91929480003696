/* eslint-disable react/jsx-curly-newline */
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import styled from 'styled-components';

import { useSellingData } from '../../../../../../context/data/SellingContext';
import { useNotification } from '../../../../../../context/NotificationContext';
import { AscendentObject } from '../../../../../../helpers/Sort';
import { queryClient } from '../../../../../../services/queryClient';

const EpiView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.background.line};
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    background-color: #55555509;
  }

  p {
    padding-right: 20px;
    width: 100%;
  }

  &.group {
    padding-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.background.line};
    margin-bottom: 1px;
    padding-bottom: 5px;
    p {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-size: 13px;
    }
  }

  &.last {
    border-bottom: 1px solid ${({ theme }) => theme.palette.background.line};
  }
`;

const Check = styled(Checkbox)`
  height: 35px;
  width: 35px;
`;

const SubCursosSideBarComponent = ({ hasSubCurso, check, isAdmin, curso }) => {
  const notification = useNotification();
  const cursosAllData = queryClient.getQueryData('cursos');
  const { fieldEdit, cursos, setCursos, setPrices, onCalcUserPrice } =
    useSellingData();

  const EPIs = curso?.subs;
  const EPI = EPIs && EPIs.sort((a, b) => AscendentObject(a, b, 'name'));

  const handleEPICheck = (event, cursoId, epi, next) => {
    const newData = { ...cursos };
    const cursoIndex = cursosAllData.findIndex(
      (i) => i.id === cursoId && !i?.editorState,
    );
    const cursoAllInfo = cursosAllData[cursoIndex];

    const isLock =
      cursos[`${fieldEdit.index}--${cursoId}--${curso.name}`] === 'lock';

    if (
      event.target.checked &&
      !next &&
      isLock &&
      !!cursoAllInfo.cursoValidation
    )
      return notification.modal({
        title: 'Importante',
        text: `Ao comprar um novo módulo de um curso que já foi finalizado e econtra-se dentro de seu périodo de validade, o aluno em questão não precisará assistir todas as aulas novamente e o preço a ser pago entra dentro dos combos oferecidos. Entretando este curso terá sua data de validade reduzida, sendo a mesma data de vencimento de quando foi finalizado pela ultima vez`,
        open: true,
        onClick: () => handleEPICheck(event, cursoId, epi, true),
      });

    newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`] = newData[
      `${fieldEdit.index}--${cursoId}--${curso.name}--epi`
    ]
      ? newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`]
      : [];

    if (event.target.checked)
      newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`] = [
        ...newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`],
        epi,
      ];

    if (!event.target.checked) {
      if (
        newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`].find(
          (i) => i.id === epi.id,
        )?.lock
      )
        return notification.warn({
          message:
            'Você não pode remover um curso que já foi iniciado pelo aluno',
        });
      newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`] = [
        ...newData[`${fieldEdit.index}--${cursoId}--${curso.name}--epi`].filter(
          (i) => i.id !== epi.id,
        ),
      ];
    }

    setPrices(onCalcUserPrice(newData));
    setCursos(newData);
  };

  return (
    <>
      {hasSubCurso && (
        <Collapse unmountOnExit in={check}>
          {EPI.map((epi) => {
            const checkEPI = Boolean(
              cursos[`${fieldEdit.index}--${curso.id}--${curso.name}--epi`] &&
                cursos[
                  `${fieldEdit.index}--${curso.id}--${curso.name}--epi`
                ].findIndex((i) => i.id == epi.id) != -1,
            );

            return (
              <EpiView
                key={String(epi.id)}
                onClick={() =>
                  handleEPICheck(
                    { target: { checked: !checkEPI } },
                    curso.id,
                    epi,
                  )
                }
              >
                <p>{epi.name}</p>
                <Check size="small" checked={checkEPI} color="primary" />
              </EpiView>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

export const SubCursosSideBar = React.memo(SubCursosSideBarComponent);
