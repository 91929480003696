import clone from 'clone';
import { useMutation } from 'react-query';
import { v4 } from 'uuid';

import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { db } from '../../../lib/firebase.prod';
import { errorCatchFirestore } from '../../error';
import { queryClient } from '../../queryClient';
import { useSendEmail } from '../post/useSendEmail';
import { onCompareCursos } from './logic/compareCursos.js';

// * based on v1

function newUser(currentUser, total, actualUser, docIdStatement) {
  if (currentUser.access === 'admin') return currentUser;
  if (currentUser.access !== 'admin') return currentUser;

  let user = { ...currentUser }; // *edit // Todo: remover cursos como distribuidor de cursos
  user = clone(user); // *edit // Todo: remover cursos como distribuidor de cursos

  const statement = [];
  if (user?.statement) statement.push(...user.statement);

  statement.push({
    id: docIdStatement,
    value: total,
    type: 'debit',
    created_at: new Date().getTime(),
    desc: 'Compra de cursos para novos membros de sua empresa que foram convidados a fazer parte da plataforma',
    buyer: actualUser.name,
  });

  return { ...user, statement };
}

export async function setUsers(checkoutInfo, actualUser) {
  //data = array of users {}
  // return later(9000)
  const currentUser = checkoutInfo.user;
  const usersRef = db.collection('users');
  const userRef = usersRef.doc(currentUser.uid);

  const statementsRef = db.collection('statement');

  const batch = db.batch();

  const total = checkoutInfo.total;
  const data = checkoutInfo.data;

  const users =
    queryClient.getQueryData(['users', currentUser.uid]) ||
    queryClient.getQueryData('clients');

  const docIdStatement = v4();
  const newCurrentUser = newUser(
    currentUser,
    total,
    actualUser,
    docIdStatement,
  );

  const reduceData = [];

  //Add statement

  const cursosAllData = queryClient.getQueryData('cursos');
  if (!checkoutInfo?.noStatement) {
    const usersArrayToStatement = [...data];
    const maxUsers = 20;
    for (
      let index = 0;
      index < usersArrayToStatement.length;
      index += maxUsers
    ) {
      console.log(`loop`);
      const products = usersArrayToStatement
        .slice(index, index + maxUsers)
        .map((user) => {
          const thisUser = users.find((i) => i.uid == user.uid);

          const compareCursos = onCompareCursos(
            thisUser.cursos,
            user.cursos,
            cursosAllData.filter((i) => !i?.editorState),
          );

          console.log('compareCursos', compareCursos);

          return {
            type: 'updatedUser',
            analytics: compareCursos,
            cursos: user.cursos,
            value:
              user.statement[0] && user.statement[0]?.value
                ? user.statement[0]?.value
                : 0,
            shared: user?.email ?? user?.link,
            data: {
              name: user?.name ? user.name : '',
              cpf: user?.cpf ? user.cpf : '',
            },
          };
        });

      batch.set(statementsRef.doc(docIdStatement), {
        id: docIdStatement,
        value: total,
        type: 'debit',
        created_at: new Date().getTime(),
        desc: 'Atualização de cursos dos membros cadastrados em sua equipe',
        buyer: actualUser.name,
        buyerId: actualUser.uid,
        billId: newCurrentUser?.billId ?? '',
        companyId: newCurrentUser?.companyId ?? '',
        customer: newCurrentUser.name,
        customerId: newCurrentUser.uid,
        products,
      });
    }
  }

  // return; // !remove

  //Edit user statements
  // if (currentUser.access !== 'admin' && false)
  //   batch.update(userRef, { statement: newCurrentUser.statement });

  //create docs
  const newData = [];
  // return console.log(`data`, data);
  data.map((user) => {
    console.log(`users`, users);
    const thisUser = users.find((i) => i.uid == user.uid);
    const cursos = thisUser && thisUser?.cursos ? thisUser.cursos : [];

    const newCurso = [];
    //aqui temos o usuario que vamos editar
    user.cursos.map((i) => {
      const hasCursos = cursos
        .filter((fi) => fi.id == i.id)
        .sort(
          (a, b) =>
            (b?.createdAt ? b?.createdAt : 0) -
            (a?.createdAt ? a?.createdAt : 16315572181490),
        );
      const cursoExists = hasCursos.find(
        (fi) =>
          fi.id == i.id &&
          (!fi?.validDate ||
            (fi?.validDate && fi.validDate >= new Date().getTime())),
      );
      // const cursoFinished = cursoExists?.status && cursoExists.status === 'finished'

      const hasCursoAlreadyInUser =
        cursoExists && !i?.isNew ? cursoExists : false;

      if (hasCursos.length > 0) {
        if (!hasCursoAlreadyInUser) newCurso.push(...hasCursos);
        else if (hasCursoAlreadyInUser) {
          const [, ...rest] = hasCursos;
          newCurso.push(...rest);
        }
      }

      if (
        hasCursoAlreadyInUser &&
        (!hasCursoAlreadyInUser?.epi ||
          !hasCursoAlreadyInUser?.status ||
          (hasCursoAlreadyInUser?.status &&
            hasCursoAlreadyInUser?.status !== 'finished') ||
          (hasCursoAlreadyInUser?.epi &&
            i?.epi &&
            hasCursoAlreadyInUser.epi.length === i.epi.length))
      ) {
        newCurso.push({
          ...hasCursoAlreadyInUser,
          epi: i?.epi ?? false,
        });
      } else if (hasCursoAlreadyInUser) {
        const { status, expireDate, finishedDate, percentage, ...rest } =
          hasCursoAlreadyInUser;
        newCurso.push({
          ...rest,
          epi: i?.epi ?? false,
          quantity: 1,
        });
      } else {
        const newI = { ...i };
        if ('isNew' in newI) {
          delete newI.isNew;
        }
        newCurso.push(i);
      }
    });

    const newUser = {};
    if (user?.name) newUser.name = user.name;
    if (user?.cpf) newUser.cpf = user.cpf;
    if (user?.cursos) newUser.cursos = newCurso;
    if (user?.cnpj) newUser.cnpj = user.cnpj;
    if (user?.razao) newUser.razao = user.razao;
    if (user?.permission) newUser.permission = user.permission;
    if (user?.statement) newUser.statement = user.statement;
    if (user?.isPrimaryAccount)
      newUser.isPrimaryAccount = user.isPrimaryAccount;
    if (user?.type) newUser.type = user.type;
    newData.push({ ...newUser, uid: user.uid });
    console.log('commit', newUser);
    batch.update(usersRef.doc(user.uid), newUser);
  });

  //commit final
  await batch.commit();

  return { data: newData, newCurrentUser, actualUser };
}

export function useUpdateUsers() {
  const notification = useNotification();
  const { currentUser, setCurrentUser } = useAuth();
  // const mutation = useSendEmail();

  return useMutation(async (data) => setUsers(data, currentUser), {
    onSuccess: (_data) => {
      if (!_data) return; // !remove
      notification.success({ message: 'Usuários alterados com sucesso!' }); //Email enviado com sucesso, verifique em sua caixa de entrada e/ou span?
      const { data, newCurrentUser, actualUser } = _data;

      const isSameUser = newCurrentUser.uid === actualUser.uid;
      if (isSameUser) setCurrentUser(newCurrentUser);

      if (!data[0]?.isPrimaryAccount)
        queryClient.setQueryData(['users', currentUser.uid], (oldData) =>
          oldData.map((i) => {
            const index = data.findIndex((fi) => fi.uid == i.uid);
            if (index === -1) return i;
            return { ...i, ...data[index] };
          }),
        );
      if (data[0]?.isPrimaryAccount)
        queryClient.setQueryData('clients', (oldData) =>
          oldData.map((i) => {
            const index = data.findIndex((fi) => fi.uid == i.uid);
            if (index === -1) return i;
            return { ...i, ...data[index] };
          }),
        );
    },
    onError: (error) => {
      notification.error({ message: errorCatchFirestore(error) });
    },
  });
}
