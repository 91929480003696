import { AscendentObject } from '../../../../helpers/Sort';

// * based on v1
export function onCalcUserPrice(cursosId, numberOfSubCursos, cursosAllData) {
  const cursoFull = cursosAllData.find(
    (i) => i.id === cursosId && !i?.editorState,
  );
  if (cursoFull?.combos) {
    const combosLength = cursoFull.combos.length;
    const cursosLength = numberOfSubCursos + 1;

    if (combosLength <= cursosLength) {
      console.log(`cursoFull.combos`, cursoFull.combos);
      // se nao tiver pacote fica mesmo preço que o combo mais caro
      return cursoFull.combos[combosLength - 1].price;
    } else {
      console.log(`cursoFull.combos`, cursoFull.combos);
      return cursoFull.combos[cursosLength - 1].price;
    }
  } else {
    return 0;
  }
}

export function onCompareCursos(oldCursos, newCursos, allCursos) {
  //{ id:, name:, value: }
  const cursosAdded = {};
  console.log(`allCursos`, allCursos);
  allCursos.map((curso) => {
    if (!cursosAdded[curso.id]) {
      cursosAdded[curso.id] = {
        quantity: 0,
        value: 0,
        name: curso.name,
        epi: {},
      };
      curso.subs &&
        curso.subs.map((epi) => {
          cursosAdded[curso.id].epi[epi.id] = {
            quantity: 0,
            name: epi.name,
          };
        });
    }

    const hasOldCursos = oldCursos
      .filter((fi) => fi.id == curso.id)
      .sort(
        (a, b) =>
          (b?.createdAt ? b?.createdAt : 0) -
          (a?.createdAt ? a?.createdAt : 16315572181490),
      );

    const oldCursoExists = hasOldCursos.find(
      (fi) =>
        fi.id == curso.id &&
        (!fi?.validDate ||
          (fi?.validDate && fi.validDate >= new Date().getTime())),
    );

    console.log(`oldCursoExists`, oldCursoExists);

    if (oldCursoExists) {
      cursosAdded[curso.id].quantity += -1;
      if (oldCursoExists?.epi) {
        oldCursoExists.epi.map((epi) => {
          cursosAdded[curso.id].epi[epi.id].quantity += -1;
          cursosAdded[curso.id].epi[epi.id].old = 1;
        });
      }
    }

    // NEW CURSOS AREA
    const hasNewCursos = newCursos
      .filter((fi) => fi.id == curso.id)
      .sort(
        (a, b) =>
          (b?.createdAt ? b?.createdAt : 0) -
          (a?.createdAt ? a?.createdAt : 16315572181490),
      );

    const newCursoExists = hasNewCursos.find(
      (fi) =>
        fi.id == curso.id &&
        (!fi?.validDate ||
          (fi?.validDate && fi.validDate >= new Date().getTime())),
    );

    if (newCursoExists) {
      cursosAdded[curso.id].quantity += 1;
      if (newCursoExists?.epi) {
        newCursoExists.epi.map((epi) => {
          cursosAdded[curso.id].epi[epi.id].quantity += 1;
          cursosAdded[curso.id].epi[epi.id].new = 1;
        });
      }
    }
  });
  // poder adicionar valores dos cursos
  const cursosEdit = {};

  Object.entries(cursosAdded).map(([keyCursoId, data]) => {
    if (data.quantity === 0 && !Object.keys(data.epi).length === 0) return;

    cursosEdit[keyCursoId] = {
      quantity: data.quantity,
      name: data.name,
      value: 0,
    };

    if (!(Object.keys(data.epi).length === 0)) {
      cursosEdit[keyCursoId].combos = {};
      cursosEdit[keyCursoId].subs = {};
      const epiOld = [];
      const epiNew = [];
      console.log(`passa aqui`);
      Object.entries(data.epi).map(([keyEpiId, dataEpi]) => {
        if (dataEpi.old === 1)
          epiOld.push({ id: keyEpiId, name: dataEpi.name });
        if (dataEpi.new === 1)
          epiNew.push({ id: keyEpiId, name: dataEpi.name });
        console.log(`passa aqui`, dataEpi);
        if (dataEpi.quantity === 0) return;
        cursosEdit[keyCursoId].subs[keyEpiId] = {
          quantity: dataEpi.quantity,
          name: dataEpi.name,
        };
      });

      const oldValue = onCalcUserPrice(keyCursoId, epiOld.length, allCursos);
      const newValue = onCalcUserPrice(keyCursoId, epiNew.length, allCursos);

      cursosEdit[keyCursoId].value = -oldValue + newValue;

      console.log(`oldValue`, oldValue);
      console.log(`newValue`, newValue);
      console.log(`newValue`, newValue);

      if (epiOld.length > 0) {
        if (!cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`])
          cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`] = {
            value: 0,
            quantity: 0,
          };

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].quantity += -1;

        // prettier-ignore
        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].value += -oldValue;
      }

      if (epiNew.length > 0) {
        if (!cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`])
          cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`] = {
            value: 0,
            quantity: 0,
          };

        cursosEdit[keyCursoId].combos[
          `combosLenth-${epiNew.length}`
        ].quantity += 1;

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].value +=  newValue;
      }

      const combinationNew = epiNew
        .sort((a, b) => AscendentObject(a, b, 'id'))
        .map((i) => i.id)
        .join('///');
      const combinationNameNew = epiNew
        .sort((a, b) => AscendentObject(a, b, 'id'))
        .map((i) => i.name)
        .join('///');
      const combinationOld = epiOld
        .sort((a, b) => AscendentObject(a, b, 'id'))
        .map((i) => i.id)
        .join('///');
      const combinationNameOld = epiOld
        .sort((a, b) => AscendentObject(a, b, 'id'))
        .map((i) => i.name)
        .join('///');

      // prettier-ignore

      if (combinationOld.length > 0) {

        if (!cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations) {
          // prettier-ignore
          cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations = {};
          cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations[combinationOld] = {value: 0, quantity:0};
        }

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations[combinationOld].quantity += -1;

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations[combinationOld].value += -oldValue;

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiOld.length}`].combinations[combinationOld].name = combinationNameOld;

      }

      if (combinationNew.length > 0) {
        // prettier-ignore
        if (!cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations) {
          // prettier-ignore
          cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations = {};
        }

        // prettier-ignore
        if (!cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations[combinationNew]) {
          // prettier-ignore
          cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations[combinationNew] = {value: 0, quantity:0};
        }

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations[combinationNew].quantity += 1;

        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations[combinationNew].value +=  newValue;
        // prettier-ignore
        cursosEdit[keyCursoId].combos[`combosLenth-${epiNew.length}`].combinations[combinationNew].name = combinationNameNew;
      }
    } else {
      // pegar preço de quem não possui sub cursos (epis)
      cursosEdit[keyCursoId].value =
        cursosEdit[keyCursoId].quantity *
        onCalcUserPrice(keyCursoId, 1, allCursos);
    }

    // cursosAddedArray.push({
    //   id: key,
    //   name: data.name,
    //   quantity: data.quantity,
    // });
  });

  return cursosEdit;
}
