/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { useMutation } from 'react-query';
import { v4 } from 'uuid';
import { useAuth } from '../../../context/AuthContext.js';

import { useNotification } from '../../../context/NotificationContext.js';
import { db } from '../../../lib/firebase.prod.js';
import { errorCatchFirestore } from '../../error.js';
import { queryClient } from '../../queryClient.js';

// interface UserData {
//   cursos: any;
//   name: string;
//   link: string | false;
//   email: string;
//   cpf: string;
//   type: string;
//   status: any;
//   creation: string;
//   createdAt: number | string;
//   uid: string;
// }

export async function setUser({ userId, status }: any) {
  const usersRef = db.collection('users').doc(userId);
  await usersRef.update({
    status,
  });
  return { userId, status };
}

export function useDeactivateUser() {
  const notification = useNotification();
  const { currentUser } = useAuth();

  return useMutation(async (data) => setUser(data), {
    onSuccess: ({ userId, status }: any) => {
      queryClient.setQueryData(['users', currentUser.uid], (oldData: any) =>
        oldData.map((user: any) => {
          if (user.uid === userId) {
            return { ...user, status };
          }
          return user;
        }),
      );
    },
    onError: (error) => {
      if (typeof error === 'string')
        setTimeout(() => {
          notification.error({
            message: error,
            modal: true,
          });
        }, 600);
    },
  });
}
