import React, { useEffect, useState } from 'react';

import {
  BottomBar,
  BoxIcon,
  FullIcon,
  NextIcon,
  PreviousIcon,
  SliderButton,
  SliderInside,
  TextSlider,
} from './style';

// import {DASHBOARD,ADMIN_PERFIL} from '../../../routes/routesNames'

export function BottomControls({
  autoplay,
  setAutoplay,
  handleNextVideo,
  handlePreviewVideo,
}) {
  return (
    <BottomBar>
      <SliderButton onClick={() => setAutoplay(!autoplay)} autoplay={autoplay}>
        <SliderInside autoplay={autoplay} />
      </SliderButton>
      <TextSlider>Autoplay</TextSlider>
      <BoxIcon onClick={handlePreviewVideo} style={{ marginLeft: 'auto' }}>
        <PreviousIcon style={{ fontSize: 24 }} />
        <span style={{ color: '#fff', fontWeight: 'bold' }}>Anterior</span>
      </BoxIcon>
      <BoxIcon onClick={handleNextVideo}>
        <span style={{ color: '#fff', fontWeight: 'bold' }}>Próximo</span>
        <NextIcon style={{ fontSize: 24 }} />
      </BoxIcon>
      <BoxIcon style={{ marginRight: '0px', width: '40px' }}>
        <FullIcon style={{ fontSize: 24 }} />
      </BoxIcon>
    </BottomBar>
  );
}
