import AccessTime from '@material-ui/icons/AccessTime';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ImportantDevices from '@material-ui/icons/ImportantDevices';
import WhatsApp from '@material-ui/icons/WhatsApp';
import React from 'react';
import {
  FaFacebookF as Facebook,
  FaInstagram as Instagram,
} from 'react-icons/fa';
import styled from 'styled-components';

const Half = styled.div`
  overflow: hidden;
  width: 50vw;
  min-width: 50vw;
  max-width: 50vw;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 45% 1fr 1fr;
  gap: 1rem;
  height: 100vh;
  background-color: #00577a;
  justify-items: flex-end;
  position: relative;
  img.arco {
    object-fit: contain;
    width: 100%;
    position: absolute;
    z-index: 10;
    transform: translate(7rem, -1rem);
  }

  @media screen and (max-width: 1220px) {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;

  img.photo {
    border-bottom-left-radius: 10px;
    object-fit: contain;
    height: 100%;
    justify-self: flex-end;
  }
  p.top {
    font-size: 1.6rem;
    color: white;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  p.link {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
  }

  &.footer {
    flex-wrap: wrap;
  }
`;

const Circle = styled.div`
  position: absolute;
  background-image: url(/images/ava.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 28vw;
  width: 28vw;
  top: 5vw;
  right: 15vw;
  display: flex;
  align-items: flex-start;
  padding-top: 10vw;
  justify-content: center;
  border-radius: 100%;
  background-color: #00577a55;
  letter-spacing: 3px;
  z-index: 11;

  p {
    line-height: 4vw;
    font-size: 2vw;
    text-align: center;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif, Arial;
  }

  @media screen and (max-height: 900px) {
    height: 25vw;
    width: 25vw;
    p {
      line-height: 3vw;
      font-size: 1.5vw;
    }
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 2rem 0.5rem 2rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  z-index: 11;

  & + div {
    margin: 0.5rem 2rem;
  }
`;

const IconDIv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8rem;
  width: 2.8rem;
  background-color: white;
  border-radius: 15px;
  margin-left: 1rem;

  svg {
    color: #00577a;
    font-size: 30px;
  }
`;

const IconSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  margin-left: 2rem;
  background-color: white;
  border-radius: 15px;

  svg {
    font-size: 35px;
  }
`;

const Footer = styled.div`
  display: flex;
  padding-top: 10rem;
  gap: 30px;
  z-index: 11;
  color: #00577a;
  padding-right: 2rem;
`;

export function HalfComponent() {
  return (
    <Half>
      <img
        className="arco"
        src="/images/arcos.png"
        alt="homem mexendo no computador"
      />
      <GridContainer>
        <img
          className="photo"
          src="/images/avafoto.png"
          alt="homem mexendo no computador"
        />
        <Circle>
          <p>
            Levando <br /> Conhecimento <br /> onde você <br /> Etiver
          </p>
        </Circle>
      </GridContainer>
      <GridContainer
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <IconsContainer>
          <p>Apostila para download</p>
          <IconDIv>
            <CloudDownload />
          </IconDIv>
        </IconsContainer>
        <IconsContainer>
          <p>100% responsivo</p>
          <IconDIv>
            <ImportantDevices />
          </IconDIv>
        </IconsContainer>
        <IconsContainer>
          <p>Flexibilidade de horários para realizar cursos</p>
          <IconDIv>
            <AccessTime />
          </IconDIv>
        </IconsContainer>
      </GridContainer>
      <GridContainer
        className="footer"
        style={{ paddingRight: '2rem', justifyContent: 'space-between' }}
      >
        <div>
          <p className="top">Conheça nossos cursos:</p>
          <a href="https://www.gruporealizaweb.com.br/ava">
            <p className="link">gruporealizaweb.com.br/ava</p>
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
            gap: '2rem',
          }}
        >
          <IconSocial>
            <a href="https://pt-br.facebook.com/GrupoRealizaRS/">
              <Facebook style={{ fontSize: 30 }} />
            </a>
          </IconSocial>
          <IconSocial>
            <a href="https://www.gruporealizaweb.com.br/#:~:text=Artigos%20e%20Not%C3%ADcias-,(51)%2099266-3248,-%3E%20Contato">
              <WhatsApp />
            </a>
          </IconSocial>
          <IconSocial>
            <a href="https://www.instagram.com/gruporealizars/">
              <Instagram />
            </a>
          </IconSocial>
        </div>
      </GridContainer>
      {/*

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <IconsContainer>
          <p>Apostila para download</p>
          <IconDIv>
            <CloudDownload />
          </IconDIv>
        </IconsContainer>
        <IconsContainer>
          <p>100% responsivo</p>
          <IconDIv>
            <ImportantDevices />
          </IconDIv>
        </IconsContainer>
        <IconsContainer>
          <p>Flexibilidade de horários para realizar cursos</p>
          <IconDIv>
            <AccessTime />
          </IconDIv>
        </IconsContainer>
      </div>
      <Footer>
        <div>
          <p className="top">Conheça nossos cursos:</p>
          <a href="https://www.gruporealizaweb.com.br/ava">
            <p className="link">gruporealizaweb.com.br/ava</p>
          </a>
        </div>
        <IconSocial>
          <a href="https://pt-br.facebook.com/GrupoRealizaRS/">
            <Facebook style={{ fontSize: 30 }} />
          </a>
        </IconSocial>
        <IconSocial>
          <a href="https://www.gruporealizaweb.com.br/#:~:text=Artigos%20e%20Not%C3%ADcias-,(51)%2099266-3248,-%3E%20Contato">
            <WhatsApp />
          </a>
        </IconSocial>
        <IconSocial>
          <a href="https://www.instagram.com/gruporealizars/">
            <Instagram />
          </a>
        </IconSocial>
      </Footer>
 */}
    </Half>
  );
}
