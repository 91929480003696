import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CardButtonHorizontal } from '../../../components/Blocks/CardButtonHorizontal';
import { CardCurso } from '../../../components/Blocks/CardCurso';
import { CertificationsStudentTable } from '../../../components/Main/Tables/CertificationsStudent';
import { MissingData } from '../../../components/Main/Tables/elements/MissingData';
import { useAuth } from '../../../context/AuthContext';
import { useLoaderDashboard } from '../../../context/LoadDashContext';
import { useNotification } from '../../../context/NotificationContext';
import { CURSOS, CURSOS_CREATE } from '../../../routes/routesNames';
import { useCursos } from '../../../services/hooks/get/useCursos';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 40px;

  @media (max-width: 768px) {
    padding-right: 20px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    overflow-x: auto;
  }
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: red; */
`;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 1rem;

  &.bottom {
    margin-bottom: 10px;
  }

  &.mt {
    margin-top: 1rem;
  }
`;

export default function Cursos() {
  const { data: dataCursos, isLoading: isLoadingCursos } = useCursos();
  const { currentUser } = useAuth();
  const history = useHistory();
  const notification = useNotification();
  const { setLoaderDash } = useLoaderDashboard();
  const isAdmin = currentUser.access === 'admin';

  useEffect(() => {
    if (!isLoadingCursos) setLoaderDash(false);
  }, [isLoadingCursos]);

  function handleChangeRoute(cursoId) {
    setLoaderDash(true);
    history.push(CURSOS + '/' + cursoId);
  }

  function handleChangeCurso() {
    setLoaderDash(true);
    history.push(CURSOS_CREATE);
  }

  const onContinueCurso = React.useMemo(
    () => () => {
      if (!(dataCursos && currentUser?.cursos)) return false;
      return currentUser.cursos
        .map((curso, index) => {
          const cursoIndex = dataCursos.findIndex((i) => i.id == curso.id);
          if (cursoIndex == -1) return null;
          const isFinished = curso?.status === 'finished';
          const isExpired =
            curso?.expireDate && new Date().getTime() > curso.expireDate;

          if (isExpired && isFinished) return null;
          if ('percentage' in curso) return true;
          // if (curso?.expireDate && 'percentage' in curso && curso.percentage < 100) return true
          return null;
        })
        .some((i) => i);
    },
    [currentUser, dataCursos],
  );

  const onAvailableAlmostCurso = React.useMemo(
    () => () => {
      // um caso disso seria distribuir o curso sem a pessoa comecar
      if (!(dataCursos && currentUser?.cursos)) return false;
      return currentUser.cursos
        .map((curso, index) => {
          const cursoIndex = dataCursos.findIndex((i) => i.id == curso.id);
          if (cursoIndex == -1) return null;
          if (curso?.quantity && curso.quantity != 0 && !curso?.status)
            return true;
          return null;
        })
        .some((i) => i);
    },
    [currentUser, dataCursos],
  );

  const onCertificationsData = () => {
    return currentUser.cursos
      .map((curso) => {
        if (curso.status == 'finished') return curso;
      })
      .filter((i) => i);
  };

  const isAvailableAlmostCurso = onAvailableAlmostCurso();
  const isContinueCurso = onContinueCurso();
  const certificationsData = onCertificationsData();

  return (
    <Container>
      {isAdmin && (
        <>
          <Title>Gerenciar Cursos</Title>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 30,
              marginBottom: 40,
              padding: '3px 0',
              overflowX: 'auto',
              overflowY: 'visible',
            }}
          >
            <CardButtonHorizontal onClick={() => handleChangeCurso()}>
              <ImportantDevicesIcon />
              <p className="title">Adicinar Novos Cursos</p>
              <p className="text">
                Click aqui para adicinar <br /> novos cursos.
              </p>
            </CardButtonHorizontal>
          </div>
        </>
      )}

      {isContinueCurso && (
        <>
          <Title>Continuar assistindo</Title>
          <div>
            {dataCursos &&
              currentUser.cursos.map((curso, index) => {
                const cursoIndex = dataCursos.findIndex(
                  (i) => i.id == curso.id,
                );
                const cursoData = dataCursos[cursoIndex];

                if (cursoIndex == -1) return null;
                const isFinished = curso?.status === 'finished';
                const isExpired =
                  curso?.expireDate && new Date().getTime() > curso.expireDate;

                if (isExpired && isFinished) return null;
                if (curso?.status && 'percentage' in curso) {
                  function getDateMessage() {
                    if (!curso.expireDate) return '';
                    const textInfo =
                      curso?.status === 'started'
                        ? 'Vencimento'
                        : 'Permanência:';
                    const actualTime = new Date().getTime();
                    if (actualTime < curso.expireDate)
                      return `${textInfo} ${new Intl.DateTimeFormat(
                        'pt-BR',
                      ).format(new Date(curso.expireDate))}`;

                    return 'expirado';
                  }

                  const text = getDateMessage();

                  return (
                    <CardCurso
                      key={curso.id}
                      onClick={() => handleChangeRoute(curso.id)}
                      image={cursoData.image}
                      title={cursoData.name}
                      text={text}
                    />
                  );
                }

                return null;
              })}
          </div>
        </>
      )}
      <Title className="mt">Cursos disponíveis</Title>
      <div>
        {dataCursos &&
          isAvailableAlmostCurso &&
          currentUser.cursos.map((curso) => {
            const cursoIndex = dataCursos.findIndex((i) => i.id == curso.id);
            const cursoData = dataCursos[cursoIndex];

            if (cursoIndex == -1) return null;
            if (curso?.quantity && curso.quantity != 0 && !curso?.status)
              return (
                <CardCurso
                  key={curso.id}
                  onClick={() => handleChangeRoute(curso.id)}
                  image={cursoData.image}
                  title={cursoData.name}
                  text={''}
                />
              );
            return null;
          })}
        {!isAvailableAlmostCurso && (
          <MissingData bigger text="Nenhum curso disponível // até o momento" />
        )}
      </div>
      <Title className="mt">Certificados disponíveis</Title>
      <CertificationsStudentTable data={certificationsData} />
    </Container>
  );
}
