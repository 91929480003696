import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../../../context/AuthContext';
import { SellingContext } from '../../../../context/data/SellingContext';
import { queryClient } from '../../../../services/queryClient';
import Carrousel from '../../../Main/Carrousel/CarrouselFirst';
import { ModalFullScreen } from '../../ModalFullScreen';
import { CheckoutPage } from './CheckoutPage';
import { FirstPageAddModal } from './FirstPage';

// import AddModal, {Type,Form} from './comp'
export function AddMemberModal({ open, setOpen, isNewClient, update }) {
  const { currentUser } = useAuth();
  const [infoModal, setInfoModal] = useState({ title: '', text: '' }); //para mandar pro modalFullScreen e dizer se ao fechar da um alerta
  const [position, setPosition] = useState(1); //posicao do carrousel

  // const {setLoad} = useLoaderScreen();
  // const notification = useNotification();
  // const dispatch = useDispatch()
  // const save = useSelector(state => state.save)

  const cursosAllData = queryClient.getQueryData('cursos');

  const [fieldEdit, setFieldEdit] = useState({ value: null, index: null }); //dados dos email inseridos nos inputs
  const [dataUser, setDataUser] = useState({});
  const [cursos, setCursos] = useState({});
  const [permissions, setPermissions] = useState({});
  const [prices, setPrices] = useState([]);
  const [credit, setCredit] = useState([]);

  const [checkoutInfo, setCheckoutInfo] = useState({});

  const onEnd = React.useCallback(() => {
    setFieldEdit({ value: null, index: null });
    setDataUser({});
    setCursos({});
    setPermissions({});
    setCheckoutInfo({});
    setPrices([]);
    setCredit([]);
    setOpen(false);
    setPosition(1);
  }, []);

  const onClose = () => {
    if (queryClient.isMutating()) return;
    onEnd();
  };

  function onGoBack() {
    setPosition((position) => position - 1);
  }

  // * v1
  function onCalcUserPrice(cursos) {
    const cursosArray = [];
    Object.keys(cursos).map((key) => {
      const fieldIndex = key.split('--')[0];
      const cursoId = key.split('--')[1];
      if (!cursosArray[fieldIndex]) cursosArray[fieldIndex] = [];

      if (!cursos[key]) return null;

      if (Array.isArray(cursos[key])) {
        //curso de epi de segunda order

        return cursosArray[fieldIndex].push(...cursos[key].map((i) => cursoId));
      } else {
        //curso normal de primeira order

        return cursosArray[fieldIndex].push(cursoId);
      }
    });

    const price = cursosArray.map((fieldArray) => {
      // [ cursoId1, cursoId1, cursoId2, ..... ]
      return fieldArray.reduce((acc, item, index) => {
        const isFirstTimePassing =
          fieldArray.findIndex((i) => i == item) == index;
        if (!isFirstTimePassing) return acc;

        const cursoFull = cursosAllData.find(
          (i) => i.id === item && !i?.editorState,
        );
        console.log(`cursoFull`, cursoFull);
        if (cursoFull?.combos) {
          const combosLength = cursoFull.combos.length;
          const cursosLength = fieldArray.filter((i) => i == item).length;
          if (combosLength <= cursosLength) {
            // se nao tiver pacote fica mesmo preço que o combo mais caro
            return acc + cursoFull.combos[combosLength - 1].price;
          } else {
            return acc + cursoFull.combos[cursosLength - 1].price;
          }
        } else {
          return acc;
        }
      }, 0);
    });

    return price; // [80,90,...]
  }

  const onBilling = () => {
    const fieldIndex = fieldEdit?.index;
    const isAdmin = currentUser?.access === 'admin';
    const isFM = permissions[`${fieldIndex}--fm`];

    if (!isAdmin) return true;
    if (isAdmin && isFM) return true;
    return false;
  };

  const isBilling = useMemo(() => onBilling(), [fieldEdit, permissions]);

  const onTotalPrice = () => {
    return prices.reduce(
      (acc, price, index) => {
        if (isBilling && !isNewClient)
          return (
            Number(acc) +
            Number(price) -
            Number(credit[index] ? credit[index] : 0)
          );
        return acc;
      },
      [0],
    );
  };

  const totalPrice = useMemo(
    () => onTotalPrice(),
    [prices, permissions, isBilling, credit],
  );

  console.log('totalPrice', totalPrice);
  console.log('credit', credit);
  console.log('isBilling', isBilling);
  console.log('dataUser', dataUser);
  console.log('cursos', cursos);
  console.log('checkoutInfo', checkoutInfo);
  console.log('permissions', permissions);
  console.log('prices', prices);
  console.log('fieldEdit', fieldEdit);

  return (
    <SellingContext.Provider
      value={{
        onCalcUserPrice,
        credit,
        setCredit,
        totalPrice,
        isBilling,
        checkoutInfo,
        setCheckoutInfo,
        dataUser,
        setDataUser,
        cursos,
        setCursos,
        permissions,
        setPermissions,
        prices,
        setPrices,
        fieldEdit,
        setFieldEdit,
      }}
    >
      <ModalFullScreen
        open={open}
        arrow={position >= 2 ? true : false}
        onClose={onClose}
        infoModal={infoModal}
        onGoBack={onGoBack}
      >
        <Carrousel sections={2} position={position}>
          <FirstPageAddModal
            setPosition={setPosition}
            onEnd={onEnd}
            isNewClient={isNewClient}
            update={update}
          />
          <CheckoutPage
            checkoutInfo={checkoutInfo}
            totalPrice={totalPrice}
            onEnd={onEnd}
            update={update}
          />
        </Carrousel>
      </ModalFullScreen>
    </SellingContext.Provider>
  );
}
