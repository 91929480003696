import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1400px;
  border-radius: 10px;
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 30px 0;
  width: 100%;
  padding-right: 40px;

  @media screen and (max-width: 1100px) {
    padding-right: 40px;
  }

  @media screen and (max-width: 700px) {
    padding-right: 15px;
  }
`;

export const CircleView = styled.div`
  border-radius: 40px;
  box-sizing: content-box;
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.palette.status.success};
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.primary.main},
    ${({ theme }) => theme.palette.primary.dark}
  );
  width: 40px;
  height: 40px;
  margin-right: 25px;
  align-items: center;
  position: relative;
  justify-content: center;
  padding-right: 40px;
`;
