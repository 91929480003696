import { alpha as fade } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CardButton } from '../../../../components/Blocks/CardButton';
import { LinksURLTable } from '../../../../components/Main/Tables/LinksURL';
import { MembersTable } from '../../../../components/Main/Tables/Members';
import { AddMemberModal } from '../../../../components/Modal/Pages/AddMember';
import { AddMemberModalExcel } from '../../../../components/Modal/Pages/AddMemberExcel';
import { useAuth } from '../../../../context/AuthContext';
import { useLoaderDashboard } from '../../../../context/LoadDashContext';
import { useLoaderScreen } from '../../../../context/LoaderContext';
import { useNotification } from '../../../../context/NotificationContext';
import { useCursos } from '../../../../services/hooks/get/useCursos';
import { useUsers } from '../../../../services/hooks/get/useUsers';
import { queryClient } from '../../../../services/queryClient';
import { Title } from './styles';

// import Header from '../../../../components/Dashboard/Components/Blocks/Header'
// import {Container,TableContainer} from './comp'
const Item = styled.button`
  display: grid;
  position: relative;
  border: none;
  background-color: ${({ theme }) => theme.palette.background.paper};
  width: 100%;
  align-items: center;
  grid-template-columns: 1.2fr 1fr 1fr;
  padding: 0.8rem 1.25rem;
  cursor: pointer;

  p {
    text-align: left;
    font-size: 0.9rem;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 1.25rem;
    width: calc(100% - 2.5rem);
    height: 1px;
    background-color: ${({ theme }) => theme.palette.background.line};
  }

  &:hover {
    background-color: ${({ theme }) => fade(theme.palette.primary.main, 0.1)};
  }
`;

function Team() {
  const { currentUser } = useAuth();
  const { data, isLoading, error } = useUsers({ currentUser });
  const { dataCursos, isLoadingCursos } = useCursos({ notDisableLoad: true });
  // const  { data:links, isLoading:linksIsLoading, error:linksError } = useLinks(3)

  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [queryOld, setQueryOld] = useState(false);
  const [usersRows, setUsersRows] = useState([]);
  const [selected, setSelected] = useState([]);

  const { setLoad } = useLoaderScreen();
  const notification = useNotification();
  const query = new URLSearchParams(useLocation().search);
  const { setLoaderDash } = useLoaderDashboard();

  useEffect(() => {
    if (query.get('m') !== queryOld && query.get('m')) setOpen(true);
    setQueryOld(query.get('m'));
  }, [query]);

  useEffect(() => {
    if (!isLoading && !isLoadingCursos) setLoaderDash(false);
  }, [isLoading, isLoadingCursos]);

  function handleOpenExcel() {
    setOpenExcel(true);
  }

  function handleOpen() {
    queryClient.prefetchQuery(['users', currentUser.uid]);
    setOpen('update');
  }

  return (
    <>
      <Title>Gerenciar Membros</Title>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 30,
          marginBottom: 40 /* padding:'3px 0',overflowX:'auto',overflowY:'visible' */,
        }}
      >
        <CardButton
          onClick={() => setOpen(true)}
          image={'/images/email.png'}
          title={'Adicionar Membros'}
          text={
            'Click aqui para adicinar // novos membro e alunos a sua // equipe.'
          }
          alt="E-mail letter"
        />
        <CardButton
          onClick={handleOpen}
          image={'/images/aprendizagem-online.png'}
          title={'Disponibilizar Cursos'}
          text={
            'Click aqui para adicinar cursos // a membros já cadastrados // em sua equipe.'
          }
          alt="Laptop"
        />
        {/* <CardButton
          onClick={handleOpenExcel}
          image={'/images/excel.png'}
          title={'Importar Planilha de Alunos'}
          text={
            'Click aqui para importar alunos // para sua equipe atravez // de uma planilha.'
          }
          alt="Laptop"
        /> */}
      </div>

      {/* <AddCard onClick={onAddMember}>
          <img style={{width:60,height:60,marginRight:'10px',padding:'10px 5px'}} src='/images/email.png'/>
          Click aqui para adicinar novos  membro e alunos a sua equipe.
          <IconButtonStyled id='IconButtonStyled'>
            <Add style={{}}/>
          </IconButtonStyled>
        </AddCard> */}
      <LinksURLTable
        data={data ? data : []}
        filter={false}
        isLoading={isLoading}
      />
      <MembersTable data={data ? data : []} isLoading={isLoading} />
      <AddMemberModalExcel open={!!openExcel} setOpen={setOpenExcel} />
      <AddMemberModal
        open={!!open}
        setOpen={setOpen}
        update={open === 'update'}
      />
    </>
  );
}

export default Team;

//               <TableContainer
//                 setLoaderDash={setLoaderDash}
//                 setLoad={setLoad}
//                 tabsLabel={['Seus Usuários']}
//                 currentUser={currentUser}
//                 notification={notification}
//                 setDataRows={setUsersRows}
//                 dataRows={usersRows}
//                 setSelected={setSelected}
//                 selected={selected}
//                 setOpen={setOpen}
//               />
