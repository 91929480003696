import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useState } from 'react';
import styled from 'styled-components';

import { DateProvider } from '../../../../helpers/DateProvider/implementation';
import { useDownloadCertification } from '../../../../services/hooks/http/useDownloadCertification';
import { LoadMoreTableCells } from '../elements/LoadMore';
import { LoadTable } from '../elements/LoadTable';
import { MissingData } from '../elements/MissingData';

// import { FilterComponent } from '../../Table/comp';

const ContainerTable = styled.main``;

const Table = styled.div``;

const TableHeader = styled.div`
  display: grid;
  margin-bottom: 0px;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
  padding: 0rem 1.75rem;
  align-items: center;
`;

const TableHComponent = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
  padding: 0.25rem 0.25rem;
  text-align: left;
  line-height: 1.5rem;
`;

const TableBody = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 5px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
  flex-shrink: 0;
  grid-template-rows: fit-content fit-content;
  border-radius: 0.25rem;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-bottom: 10px;
  padding: 1rem 1.75rem;
  align-items: center;
  /* align-self: center; */
  color: ${({ theme }) => theme.palette.text.primary};
`;

const TableBComponent = styled.div`
  padding: 0rem 0.25rem;
`;

const Certification = styled.div`
  display: flex;
  width: fit-content;
  min-width: fit-content;
  gap: 10px;
  cursor: pointer;

  p {
    width: fit-content;
    min-width: fit-content;
  }

  svg {
    color: #999;
    font-size: 1.1rem;
  }
`;

export function CertificationsStudentTable({ isLoading, data }) {
  const DATA = data;
  const dateProvider = new DateProvider();

  const downloadCertificate = useDownloadCertification();

  const handleDownloadCertificate = (studentId) => {
    console.log(`studentId`, studentId);
    if (downloadCertificate.isLoading) return;
    downloadCertificate.mutateAsync({
      certificationId: studentId,
    });
  };

  const cursosText = (item) => {
    if (item?.epi) {
      const epis = item.epi
        .map((epi) => {
          return epi.name;
        })
        .join(', ');
      return `${item.name} (${epis})`;
    }
    return `${item.name}`;
  };

  return (
    <ContainerTable>
      {!isLoading ? (
        DATA.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableHComponent>Curso</TableHComponent>
                <TableHComponent>Finalizado</TableHComponent>
                <TableHComponent>Vencimento</TableHComponent>
                <TableHComponent>Baixar</TableHComponent>
              </TableHeader>
              <TableBody>
                {DATA.map((item) => {
                  const curso_name = cursosText(item);
                  return (
                    <TableRow key={item.id}>
                      <TableBComponent>
                        <span>
                          {curso_name ? curso_name : '----------------'}
                        </span>
                      </TableBComponent>
                      <TableBComponent>
                        <span>
                          {item?.finishedDate
                            ? dateProvider.format().timeAgo(item.finishedDate)
                            : '----------------'}
                        </span>
                      </TableBComponent>
                      <TableBComponent>
                        <span>
                          {item?.validDate
                            ? dateProvider.format().long(item.validDate)
                            : '----------------'}
                        </span>
                      </TableBComponent>
                      <TableBComponent>
                        <Certification
                          onClick={() =>
                            handleDownloadCertificate(item.studentId)
                          }
                        >
                          {downloadCertificate.isLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <GetAppIcon />
                          )}
                          <p>Baixar Modelo</p>
                        </Certification>
                      </TableBComponent>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <LoadMoreTableCells shown={DATA.length} total={DATA.length} />
          </>
        ) : (
          <MissingData bigger text={'Nenhum dado // disponivel no momento'} />
        )
      ) : (
        <LoadTable rows={5} columns={5} />
      )}
    </ContainerTable>
  );
}
