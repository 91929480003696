import Collapse from '@material-ui/core/Collapse';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../context/AuthContext';
import { useLoaderDashboard } from '../../../context/LoadDashContext';
import { useLoaderScreen } from '../../../context/LoaderContext';
import { useNotification } from '../../../context/NotificationContext';
import useFade from '../../../hooks/useFadeInOut';
import useTimeOut from '../../../hooks/useTimeOut';
import { DASHBOARD } from '../../../routes/routesNames';
import { useLink } from '../../../services/hooks/get/useLink';
import Sign, { ContinueButton, PolicyTerms } from './comp';
import {
  onCheckEmail,
  onCreateAccount,
  onLoginUser,
  onRecoveryEmail,
} from './func';
import { Form, Title } from './styles';
import {
  confirmHandlePasswordChange,
  handleEmailChange,
  handlePasswordChange,
} from './valid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
  FaFacebookF as Facebook,
  FaInstagram as Instagram,
  FaLinkedinIn as LinkedIn,
} from 'react-icons/fa';
import WhatsApp from '@material-ui/icons/WhatsApp';
import AccessTime from '@material-ui/icons/AccessTime';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ImportantDevices from '@material-ui/icons/ImportantDevices';
import { HalfComponent } from './Half';

const Footer = styled.div`
  display: flex;
  padding-top: 10rem;
  gap: 30px;
  z-index: 11;
  color: #00577a;
  padding-right: 2rem;
  p.top {
    font-size: 1.8rem;
    color: white;
  }

  a {
    color: inherit;
  }
  p.link {
    font-size: 2.2rem;
    font-weight: bold;
    color: white;
  }
`;

const Circle = styled.div`
  position: absolute;
  background-image: url(/images/ava.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 33rem;
  width: 33rem;
  display: flex;
  align-items: flex-start;
  margin-top: 6rem;
  margin-right: 20rem;
  padding-top: 12.6rem;
  justify-content: center;
  border-radius: 100%;
  background-color: #00577a55;
  /* filter: saturate(0.7); */
  letter-spacing: 3px;
  p {
    line-height: 4.4rem;
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif, Arial;
  }
`;

const Half = styled.div`
  overflow: hidden;
  width: 50vw;
  padding-left: 1rem;
  min-width: 50vw;
  max-width: 50vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48% 20% 20%;
  gap: 1rem;
  height: 100vh;
  background-color: #00577a;
  justify-items: flex-end;
  position: relative;
  img {
    &.photo {
      border-bottom-left-radius: 10px;
      object-fit: contain;
      height: 100%;
      justify-self: flex-end;
    }
    &.arco {
      object-fit: contain;
      width: 100%;
      repeat: repeat-y;
      position: absolute;
      z-index: 10;
      transform: translate(7rem, -1rem);
    }
  }

  @media screen and (max-width: 1220px) {
    display: none;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 2rem 0.5rem 2rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  z-index: 11;

  & + div {
    margin: 0.5rem 2rem;
  }
`;

const IconDIv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8rem;
  width: 2.8rem;
  background-color: white;
  border-radius: 15px;
  margin-left: 1rem;

  svg {
    color: #00577a;
    font-size: 30px;
  }
`;

const IconSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  margin-left: 2rem;
  background-color: white;
  border-radius: 15px;

  svg {
    font-size: 35px;
  }
`;

export default function SignIn({ emailQuery, codeQuery }) {
  const initialState = {
    //emailAddress: emailQuery ? emailQuery:'rodrigobanselmo@gmail.com',
    emailAddress: emailQuery ? emailQuery : '',
    password: '',
    confirmPassword: '',
    warnMessage: { body: 'Campo de email não pode ser nulo', type: 'none' },
    warnPassMessage: { body: 'Campo de senha não pode ser nulo', type: 'none' },
    warnConfirmMessage: {
      body: 'Confirme sua senha para continuar',
      type: 'none',
    },
  };

  const history = useHistory();

  const [error, setError] = useState('');
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [login, setLogin] = useState(false); //tels us with state the component is // Enter-Login-Register
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const [code, setCode] = useState(0);

  const [update, setUpdate] = useState(false); // will tell us if need to check the email input

  const inputPass = useRef(null);
  const inputConfirm = useRef(null);

  const { currentUser } = useAuth();
  const { load, setLoad } = useLoaderScreen();
  const { setLoaderDash } = useLoaderDashboard();
  const notification = useNotification();

  const [fade, change, fadeInOut] = useFade('Entrar');
  const [onTimeOutReset] = useTimeOut(); //to undo an error message and if i sent a new error will erase the old setTimeout to undo it
  const [onTimeOutR, onClearTimeO] = useTimeOut(); //to see if email is valid and if user stopped texting

  const { data: linkData } = useLink(codeQuery ? codeQuery : 0);
  console.log('linkData', linkData);

  const validCode = Array.isArray(linkData) && linkData.length === 1;

  useEffect(() => {
    if (emailQuery) {
      setTimeout(() => {
        onButtonSign();
      }, 800);
    }
  }, []);

  useEffect(() => {
    //redirect to app if user is logged  and if user email is coming from query it will valid it
    if (currentUser) {
      setLoaderDash(true);
      setTimeout(() => {
        // setLoaderDash(false)
        history.replace(DASHBOARD);
      }, 1000);
    }
  }, [currentUser]);

  useEffect(() => {
    if (error && error !== '') onTimeOutReset(() => setError(''), 4000);
  }, [error]);

  useEffect(() => {
    //when email input is lazy and email is valid will call this checkEmail func
    update && onTimeOutR(onButtonSign, 1500);
  }, [update]);

  function onChangeAuthMethod(type) {
    //it will change the title of the form box
    let message = 'Entrar';
    if (type === 'register') message = 'Cadastro';
    else if (type === 'login') message = 'Login';
    fadeInOut(() => {
      setLogin(type);
    }, message);
  }

  const onButtonSign = (event) => {
    event && event?.preventDefault && event.preventDefault();
    if (!loading) {
      if (login === false) {
        onCheckEmail({
          data,
          setData,
          setLoading,
          setError,
          onChangeAuthMethod,
          inputPass,
          onErrorNotification,
          validCode,
        });
        onClearTimeO();
      } else if (login === 'login') {
        onLoginUser({
          data,
          setLoad,
          setLoaderDash,
          setError,
          onErrorNotification,
        });
      } else if (login === 'register') {
        if (!checked) return setError('Preencha todos os campos acima!');
        onCreateAccount({
          data,
          setLoad,
          setLoaderDash,
          setError,
          onErrorNotification,
          validCode,
          linkData: linkData[0],
        });
      }
    }
  };

  function onSentRecoveryEmail() {
    onRecoveryEmail({
      data,
      setLoad,
      setError,
      onSuccessNotification,
      onErrorNotification,
      setRecoveryModal,
    });
  }

  function onSetEmailAddress(value) {
    handleEmailChange(value, data, setData, setUpdate, onClearTimeO);
  }

  function onSetPassword(value) {
    handlePasswordChange(value, data, setData);
  }

  function onSetConfirmPassword(value) {
    confirmHandlePasswordChange(value, data, setData);
  }

  function onFocusEmail(value) {
    //every time I focus the email will disappear the others inputs
    if (login !== false) {
      setData({ ...initialState, emailAddress: value });
      setLogin(false);
    }
  }

  function onErrorNotification(error) {
    notification.error({ message: error });
  }

  function onSuccessNotification(message) {
    notification.success({ message });
  }

  return (
    <div style={{ display: 'flex' }}>
      <HalfComponent />
      <Sign>
        {/* <Sign.VideoBackground/> */}
        <Form style={{ transform: 'scale(1)' }}>
          <Title fade={fade}>{change}</Title>
          <Collapse timeout={1000} in={error && error !== '' ? true : false}>
            <Sign.Errors error={error} />
          </Collapse>
          <div style={{ position: 'relative' }}>
            <Sign.InputEmail
              loading={loading}
              data={data}
              onSetEmailAddress={onSetEmailAddress}
              /*  onBlurEmail={onBlurEmail} */
              onFocusEmail={onFocusEmail}
              onKerPress={({ key }) => key === 'Enter' && onButtonSign()}
            />
            <Sign.InputPass
              inputPass={inputPass}
              data={data}
              loading={loading}
              login={login}
              onSetPassword={onSetPassword}
              onKerPress={({ key }) =>
                key === 'Enter' &&
                (login === 'register'
                  ? inputConfirm.current.focus()
                  : onButtonSign())
              }
            />
            <Sign.InputConfirmPass
              inputConfirm={inputConfirm}
              data={data}
              onSetConfirmPassword={onSetConfirmPassword}
              login={login}
              loading={loading}
            />
            <Sign.ForgotPassword
              login={login}
              setRecoveryModal={setRecoveryModal}
            />
            <PolicyTerms
              handleChange={() => setChecked(!checked)}
              checked={checked}
              login={login}
            />
            <ContinueButton
              handleSignIn={onButtonSign}
              login={login}
              data={data}
            />
          </div>
          {/* <div onClick={() => onLoginUser({data,setLoad,setError,onErrorNotification})}>jio</div> */}
          <Sign.Duvida />
        </Form>
        <Sign.RecoveryModal
          email={data.emailAddress}
          onSentRecoveryEmail={onSentRecoveryEmail}
          setRecoveryModal={setRecoveryModal}
          recoveryModal={recoveryModal}
        />
      </Sign>
    </div>
  );
}
