import { useMutation } from 'react-query';

import { keepOnlyNumbers } from '../../../../helpers/StringHandle';
import { db } from '../../../../lib/firebase.prod';

export async function getMore(searchValue) {
  const searchRef = db.collection('certifications');
  const isString =
    !keepOnlyNumbers(searchValue.substring(0, 1)).length > 0 ? true : false;
  const value = isString
    ? searchValue.toUpperCase()
    : searchValue.normalize('NFD').replace(/[^a-zA-Z0-9s]/g, '');

  const LIMIT = 10;

  const arrayData = [];

  async function searchStringUsers() {
    if (isString) {
      await Promise.all(
        ['cursoName', 'email', 'name'].map(async (item) => {
          console.log(item, value);
          const users = await searchRef
            .where(item, '>=', value)
            .where(
              item,
              '<=',
              String.fromCharCode(value.substring(0, 1).charCodeAt() + 1),
            )
            .limit(LIMIT)
            .get();

          users.forEach(function (doc) {
            arrayData.push({ ...doc.data() });
          });
          return null;
        }),
      );
    }

    if (!isString) {
      await Promise.all(
        ['cpf'].map(async (item) => {
          const users = await searchRef
            .where(item, '>=', value)
            .limit(LIMIT)
            .get();

          users.forEach(function (doc) {
            arrayData.push({ ...doc.data() });
          });
          return null;
        }),
      );
    }

    return arrayData;
  }

  const array = await searchStringUsers();
  return array;
}

export function useSearchCertifications() {
  return useMutation(async (value) => getMore(value), {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    onSuccess: (data) => {
      return data;
    },
  });
}
