import axios from 'axios';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../context/AuthContext';
import { useLoaderDashboard } from '../../../context/LoadDashContext';
import { useNotification } from '../../../context/NotificationContext';
import { Container } from './style';

export default function HomeAdmin() {
  const { setLoaderDash } = useLoaderDashboard();
  const notification = useNotification();
  const { currentUser } = useAuth();

  const pathname = '/app/admin/video';

  useEffect(() => {
    setLoaderDash(false);
  }, []);

  // const createAndDownloadPdf = () => {
  //   console.log(0);
  //   axios()
  //     .then((res) => {
  //       const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
  //       console.log(1);
  //       saveAs(pdfBlob, 'newPdf.pdf');
  //     })
  //     .catch(async (error) => {
  //       // const errorString = JSON.parse(await error.response.data.text());
  //       // console.log(errorString.message)
  //       notification.error({ message: 'Erro ao gerar certificado' });
  //       console.log('error', error.response);
  //     });
  // };

  return (
    <Container>
      <img
        style={{ objectFit: 'contain', width: '100%', borderRadius: '20px' }}
        alt="quem somos"
        src="https://static.wixstatic.com/media/77b0be_e67e680122334bb3ac9ed91ec21cb563~mv2.jpg/v1/fill/w_3000,h_760,al_c,q_90/77b0be_e67e680122334bb3ac9ed91ec21cb563~mv2.webp"
      />
    </Container>
  );
}

//https://www.codingdeft.com/posts/react-upload-file-progress-bar/
//https://quantizd.com/building-a-video-converter-app-with-node-js-express-and-react/
{
  /* <Logo  height="400px" width="400px"/> */
}
